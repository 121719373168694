import {API_FILE_MANAGEMENT} from "../constants/apiEndPoints";
import CoreAPI from "./CoreAPI";

class CommonService extends CoreAPI {
    
    /**
     *
     * @param {*} body
     * @returns
     */
  
    uploadFile(body) {
      this.setAuthenticationHeader(this.getToken());
      return this.postRequest(API_FILE_MANAGEMENT.UPLOAD_FILE,body);
    }

    /**
     *
     * @param {*} body
     * @returns
     */
    deleteFile(body) {
      this.setAuthenticationHeader(this.getToken());
      return this.postRequest(API_FILE_MANAGEMENT.DELETE_FILE,body);
    }
  
  }
  
  export default CommonService;