import {
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Button,
  Image,
  Upload,
} from "antd";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { useState, useEffect } from "react";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import AthleteService from "../../../service/AthleteService";
import { STORAGE } from "../../../Enum";
import addDeleteGetLocalStorage from "../../../prototype/addDeleteGetLocalStorage";
import { useAlert } from "../../../contexts/AlertProvider";
import moment from 'moment';
import { handleKeyDownOnLength, validateUrl, validatePhoneNumber } from "../../../utils/validation";
import { Config } from "../../../constants/Config";
import { useLoader } from "../../../contexts/LoadingProvider";
import { decodeId } from '../../../utils/encoding';
import PlacesSearch from '../../../contexts/PlaceSearch';
import CommonService from "../../../service/CommonService";
const CommonSer = new CommonService();


const AthleteSer = new AthleteService();

const club = [];

const heightUnit = [
  { value: "inch", label: "inch" },
  { value: "cm", label: "cm" },
];
const weightUnit = [
  { value: "lbs", label: "lbs" },
  { value: "kilos", label: "kilos" },
];
const genderUnit = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Non-binary", label: "Non-binary" },
  { value: "Other", label: "Other" },
];
const Citizenship = [
  { value: "United States", label: "United States" },
];
const Committed = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];
const CountryList = [
  { value: "USA", label: "USA" }
];
const PersonalInformation = () => {
  const { id } = useParams();
  const athleteId = decodeId(id);
  const [form] = Form.useForm();
  const { showAlert } = useAlert();
  const { setLoading } = useLoader();
  const [phoneNumberInput, setPhoneNumberInput] = useState();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [personalDetails, setPersonalDetails] = useState({});
  const [fields, setFields] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [age, setAge] = useState('');
  const allowedTypes = ["image/jpeg", "image/png"];
  const [imageUrl, setImageUrl] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [isCommittedSchool, setIsCommittedSchool] = useState([]);
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  const [details, setDetails] = useState({ zipcode: '', lat: '', lng: '', city: '', state: '', country: '' });

  useEffect(() => {
    const fetchProfileData = async () => {
      setLoading(true);
      const response = await AthleteSer.getAthletePersonalDetails(athleteId);
      const data = response?.data;
      setPersonalDetails(data);
      if (data && data.profile_image) {
        setPreviewImage(data.profile_image);
      }
      setIsCommittedSchool(response?.data?.is_committed_school);
      setAddress(response?.data?.user_address?.address);
      calculateAge(response?.data?.dob);
      const socialMediaLinks = {};
        data?.social_media_link?.forEach(link => {
          socialMediaLinks[link.name] = link.link;
        });

        // Initialize travel team fields
        const travelTeams = data?.travel_team || [];
        setFields(travelTeams);
        
        // Set form initial values
        const initialValues = {
          ...data,
          ...socialMediaLinks,
          ...data.user_address,
        };
        
        travelTeams.forEach((team, index) => {
          initialValues[`Team${index + 1}`] = team;
        });
        if (data?.profile_image) {
          setImageUrl(data.profile_image);
          setFileList([{
            uid: '-1',
            name: 'profile_image',
            status: 'done',
            url: data.profile_image,
          }]);
        }
        form.setFieldsValue(initialValues);
        setLoading(false);
      addDeleteGetLocalStorage(STORAGE.SPORTS, ((response?.data?.sport_cat)?(response?.data?.sport_cat.map(Number)):'[]'), "add", "single");
    }
    fetchProfileData();
  }, [athleteId,form]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AthleteSer.getState();
        setStates(response?.data);
        const state = response.data.find((item) => item.name === personalDetails?.user_address?.state);
        if (state) {
          setSelectedState(state.id);
          form.state = parseInt(state.id);
        }
      } catch (error) {
        //showAlert('Failed to fetch states', 'error');
      }
    };
    fetchData();
  }, [personalDetails]);
  useEffect(() => {
    if (selectedState) {
      const fetchCities = async () => {
        try {
          const response = await AthleteSer.getCity(selectedState);
          setCities(response?.data);
        } catch (error) {
          //showAlert('Failed to fetch cities', 'error');
        }
      };
      fetchCities();
    }
  }, [selectedState]);
  // const [fileList, setFileList] = useState([
  //   {
  //     uid: "-1",
  //     name: "image.png",
  //     status: "done",
  //     url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
  //   },
  // ]);
  

  useEffect(() => {
    form.setFieldsValue({
      country: details?.country,
      zipcode: details?.zipcode,
      city: details?.city,
      state: details?.state,
    });
  }, [details, form]);

  const addField = () => {
    setFields([...fields, {}]);
  };

  const removeField = (index) => {
    const updatedFields = fields.filter((_, idx) => idx !== index);
    setFields(updatedFields);
  };

  const handlePreview = async (file) => {
    setPreviewImage(file.url || file.thumbUrl);
    setPreviewOpen(true);
  };
  const handleFieldChange = (index, value) => {
    const newFields = fields.slice();
    newFields[index] = value;
    setFields(newFields);
  };
  const handleChange = async ({url}) => {
    // if (fileList.length > newFileList.length) {
    //   const removedFile = fileList.find(file => !newFileList.includes(file));
    //   if (removedFile) {
       await deleteFileFromS3BucketByUrl(url);
    //   }
    // }
   setFileList([]);
   setImageUrl('');
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  for (let i = 10; i < 36; i++) {
    club.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i,
    });
  }

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    if (isNaN(birthDate.getTime())) {
      return 0;
    }
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }
    setAge(age >= 0 ? age : 0);
    //return age >= 0 ? age : 0;
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const maxSizeInMB = 5; // 5MB limit
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

    if (selectedFile) {
      if (allowedTypes.includes(selectedFile.type)) {
        if (selectedFile.size <= maxSizeInBytes) {
          uploadFileToS3Bucket(selectedFile);
        } else {
          showAlert("File size exceeds 5MB limit.", "error");
        }
      } else {
        showAlert("Invalid file type.", "error");
      }
    }
  };

  const uploadFileToS3Bucket = async (file) => {
    if (!file) {
      showAlert("Please select a file first!", "error");
      return;
    }
    try {
      const renamedFile = new File([file], file?.name, { type: file.type });
      let payload = {
        user_id: athleteId,
        image: renamedFile,
        field: "",
        type: "image",
        page:"athlete_personal_details",
      }
      const formData = new FormData();
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });
      let response = await CommonSer.uploadFile(formData);
      setImageUrl(response?.data);
      setPreviewImage(response?.data);
      setFileList([{
        uid: '-1',
        name: 'profile_image',
        status: 'done',
        url: response?.data,
      }]);
    } catch (error) {
      showAlert(error.message, "error");
    }
  };

  const deleteFileFromS3BucketByUrl = async (fileUrl) => {
  //  const key = extractKeyFromUrl(imageUrl);  //fileUrl
    try {
      let payload = {
         user_id: athleteId,
         url: fileUrl,
         field:"profile_image",
         type: "image",
         page:"athlete_personal_details"
       };
       let response = await CommonSer.deleteFile(payload); 
      setImageUrl('');
    } catch (error) {
      alert(error.message);
      showAlert(error.message, "error");
    }
  };

  const extractKeyFromUrl = (url) => {
    const urlParts = url.split("/");
    return urlParts.slice(3).join("/");
  };

  const handleSubmit = async (values) => {
    if(!address){
      setAddressError("Please select address.");
      return;
    }
    const socialMediaLinks = ['facebook', 'instagram', 'youtube', 'twitter'].map((name) => ({
      name,
      link: values[name] || '',
    })).filter(link => link.link);
    const state = states.find((item) => item.id === values.state);
    setLoading(true);
    try {
      if (state) {
        values.state = state.name;
      }
      await AthleteSer.updatePersonalDetails({ 
        ...values, id: athleteId, 
        profile_image: imageUrl,
        state_id: selectedState,
        travel_team: fields, 
        address: address,
        social_media_link: socialMediaLinks 
      });
      showAlert("Profile updated successfully!", "success");
    } catch (error) {
      let msg = AthleteSer.errorMessage(error);
      showAlert(msg, "error");
    }
    setLoading(false);
  };

  const handleCommittedSchoolChange = (val) => {
    setIsCommittedSchool(val)
  };

  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  const onPlaceSelect = (placeDetails) => {
    setDetails(placeDetails);
    setAddress(placeDetails?.address);
    setAddressError("");
  };

  return (
    <>
      <Card>
        <div className="font-semibold text-base">Athlete Info</div>
        <Form
          form={form}
          layout="vertical"
          style={{
            //   maxWidth: 600,
            width: "100%",
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={handleSubmit}
          onFinishFailed={(errorInfo) => {
            // Handle form submission failure (validation errors)
            showAlert("Please fix the errors in the form.", "error");
          }}
        >
          <Row>
            <Col span={6}>
              <div className="mr-4">
                <Form.Item
                  label="Profile Picture"
                  valuePropName="fileList"
                >
                  <Upload
                    listType="picture-circle"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onRemove={handleChange}
                    onChange={({ file, fileList }) => {
                      if (imageUrl) return;
                      const isValid = handleFileChange({ target: { files: [file] } });
                      if (isValid) {
                        setFileList(fileList);
                      }
                    }}
                    maxCount={1}
                    beforeUpload={() => false}
                  >
                    {fileList.length >= 1 ? null : uploadButton}
                  </Upload>
                </Form.Item>
                {previewImage && (
                  <Image
                    wrapperStyle={{
                      display: "none",
                    }}
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: (visible) => setPreviewOpen(visible),
                      afterOpenChange: (visible) =>
                        !visible && setPreviewImage(""),
                    }}
                    src={previewImage}
                  />
                )}
              </div>
            </Col>
            <Col span={6}>
              <Form.Item label="Registered Date">{moment(personalDetails?.created_at).format('MM/DD/YYYY')}</Form.Item>
            </Col>
          </Row>
        
        <div>
          <div className="font-semibold text-base mb-[10px]">
            Personal Information:
          </div>
            <Row>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your First Name.",
                      },
                    ]}
                  >
                    <Input size="large" maxLength={100}/>
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Last Name"
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Last Name.",
                      },
                    ]}
                  >
                    <Input size="large" maxLength={100}/>
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="DOB"
                    name="DOB"
                  >
                   <DatePicker placeholder={moment(personalDetails?.dob).format('MM/DD/YYYY')} className="w-full h-[38px]" disabled readOnly/>
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Age"
                    name="age"
                  >
                    <Input size="large" placeholder={age} readOnly disabled/>
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <Row className="w-full">
                  <Col span={12}>
                    <div className="mr-4">
                      <Form.Item
                        label="Height"
                        name="height"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Height.",
                          },
                        ]}
                        className="w-full"
                      >
                        <InputNumber
                          min={1}
                          className="w-full h-[37px]"
                          formatter={(value) => (value ? `${value}` : '')}
                          parser={(value) => value.replace(/[^\d]/g, '')}
                          onKeyDown={(event) => handleKeyDownOnLength(event, 5)}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12}>
                  <div className="mr-4">
                      <Form.Item
                        label="Height Unit"
                        name="height_unit"
                        rules={[
                          {
                            required: true,
                            message: "Please select height unit.",
                          },
                        ]}
                      >
                        <Select size="large" options={heightUnit} />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="mr-4">
                      <Form.Item
                        label="Weight"
                        name="weight"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Weight.",
                          },
                        ]}
                        className="w-full"
                      >
                        <InputNumber
                          min={1}
                          formatter={(value) => (value ? `${value}` : '')}
                          parser={(value) => value.replace(/[^\d]/g, '')}
                          onKeyDown={(event) => handleKeyDownOnLength(event, 5)}
                          className="w-full h-[37px]"
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12}>
                  <div className="mr-4">
                      <Form.Item
                        label="Weight Unit"
                        name="weight_unit"
                        rules={[
                          {
                            required: true,
                            message: "Please select weight unit.",
                          },
                        ]}
                      >
                        <Select size="large" options={weightUnit} />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </Col>
             
              <Col span={10}>
                <div className="mr-4">
                <Form.Item
                  label="Phone no"
                  name="mobile"
                  // rules={[
                  //   { required: true, message: 'Please enter your Phone no.' },
                  //   { validator: validatePhoneNumber },
                  // ]}
                >
                  <Input
                    size="large"
                    maxLength={25}
                    onKeyPress={handleKeyPress}
                    addonBefore={
                      <div className="pointer-events-none flex items-center">
                        <span className="text-small flex items-center gap-3 mr-2">+1</span> |
                      </div>
                    }
                  />
                </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Gender"
                    name="gender"
                    rules={[
                      {
                        required: true,
                        message: "Please select gender.",
                      },
                    ]}
                  >
                    <Select size="large" options={genderUnit} />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Citizenship"
                    name="citizen_ship"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Citizenship.",
                      },
                    ]}
                  >
                    <Select size="large" options={Citizenship} />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Parent Email Address1"
                    name="parent_email_1"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Parent email address.",
                      },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Parent Email Address2"
                    name="parent_email_2"
                  >
                    <Input size="large" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Coach Email Address"
                    name="coach_email"
                  >
                    <Input size="large" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Committed School"
                    name="is_committed_school"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Committed School.",
                      },
                    ]}
                  >
                    <Select size="large" options={Committed} onChange={value => handleCommittedSchoolChange(value)}/>
                  </Form.Item>
                </div>
              </Col>
              {isCommittedSchool === "Yes" && (
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Committed High School"
                    name="committed_school"
                  >
                    <Input size="large" />
                  </Form.Item>
                </div>
              </Col>
              )}
              {/* <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="DOB"
                    name="DOB"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your DOB.",
                      },
                    ]}
                  >
                    <DatePicker
                      format="MM/DD/YYYY"
                      className="w-full h-[38px]"
                    />
                  </Form.Item>
                </div>
              </Col> */}
            </Row>
        </div>
        <div>
          <div className="font-semibold text-base mb-[10px]">
            Permanent Address details:
          </div>
            <Row>
            <Col span={20}>
                <div className="mr-4">
                <PlacesSearch initialAddress={address} onPlaceSelect={onPlaceSelect} />
                  {/* <Form.Item
                    label="Address"
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Address .",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      ref={inputRef}
                      value={address}
                      onChange={handleChange}
                    />
                  </Form.Item> */}
                  <div class="ant-form-item-explain-error" style={{ color: "#ff4d4f" }}>{addressError}</div>
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              
              <Col span={20}>
                <Row>
                <Col span={6}>
                    <div className="mr-4">
                      <Form.Item
                        label="Country"
                        name="country"
                        rules={[
                          {
                            required: true,
                            message: "Please select your Country.",
                          },
                        ]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="mr-4">
                      <Form.Item
                        label="State"
                        name="state"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your State.",
                          },
                        ]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="mr-4">
                      <Form.Item
                        label="City"
                        name="city"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your City.",
                          },
                        ]}
                      >
                      <Input size="large" />
                      </Form.Item>
                    </div>
                  </Col>

                  <Col span={6}>
                    <div className="mr-4">
                      <Form.Item
                        label="Zip Code"
                        name="zip_code"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Zip Code.",
                          },
                        ]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
        </div>
        <div>
          <div className="flex gap-2 items-center">
            <div className="font-semibold text-base mb-[10px]">
              Travel/Club team:
            </div>
            <PlusOutlined
              onClick={addField}
              className="bg-[#0071f1] text-white !p-2 rounded-lg "
            />
          </div>
          {fields.map((field, index) => (
              <Row key={index} className="items-center">
                <Col span={18}>
                  <div className="mr-4">
                    <Form.Item label={`Team ${index + 1}`} name={`Team${index + 1}`}>
                      <Input
                        size="large"
                        value={field}
                        onChange={(e) => handleFieldChange(index, e.target.value)}
                      />
                    </Form.Item>
                  </div>
                </Col>
                {index > 0 && (
                  <Col span={2}>
                    <CloseOutlined onClick={() => removeField(index)} />
                  </Col>
                )}
              </Row>
            ))}
        </div>
        <div>
          <div className="font-semibold text-base mb-[10px]">
            Social Media Links:
          </div>
            <Row>
              <Col span={5}>
                <div className="mr-4">
                  <Form.Item label="Instagram" name="instagram"
                  rules={[
                    {
                      validator: validateUrl,
                    },
                  ]}>
                    <Input size="large" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={5}>
                <div className="mr-4">
                  <Form.Item label="Youtube" name="youtube" 
                  rules={[
                    {
                      validator: validateUrl,
                    },
                  ]}>
                    <Input size="large" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={5}>
                <div className="mr-4">
                  <Form.Item label="Facebook" name="facebook"
                  rules={[
                    {
                      validator: validateUrl,
                    },
                  ]}>
                    <Input size="large" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={5}>
                <div className="mr-4">
                  <Form.Item label="Twitter" name="twitter"
                  rules={[
                    {
                      validator: validateUrl,
                    },
                  ]}>
                    <Input size="large" />
                  </Form.Item>
                </div>
              </Col>
            </Row>
        </div>
        <div>
          <div className="font-semibold text-base mb-[10px]">
            About Athlete:
          </div>
            <Row>
              <Col span={20}>
                <div className="mr-4">
                  <Form.Item label="Description" name="user_bio">
                    <Input.TextArea showCount maxLength={250} size="large" />
                  </Form.Item>
                </div>
              </Col>
            </Row>
        </div>
        <Row>
          <Col span={20}>
            <div className="flex justify-end my-5 mr-4">
              <Button
                type="primary"
                size={14}
                className="mx-1.5 cursor-pointer flex items-center justify-between"
                htmlType="submit"
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
        </Form>
      </Card>
    </>
  );
};
export default PersonalInformation;