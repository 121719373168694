import { useState, useEffect } from "react";
import { Card, Col, Divider, Row, Image, Upload, Switch } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import EditEmailAthlete from "../../../Modal/EditEmailAthlete";
import AthleteService from "../../../service/AthleteService";
import { STORAGE } from "../../../Enum";
import addDeleteGetLocalStorage from "../../../prototype/addDeleteGetLocalStorage";
import { useAlert } from "../../../contexts/AlertProvider";
import moment from 'moment';
import { decodeId } from '../../../utils/encoding';

const AthleteSer = new AthleteService();
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const PersonalInformation = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const athleteId = decodeId(id);
  const { showAlert } = useAlert();
  const [isActive, setIsActive] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [isEditEmailModalOpen, setIsEditEmailModalOpen] = useState(false);
  const [personalDetails, setPersonalDetails] = useState({});
  const [age, setAge] = useState('');
  useEffect(() => {
    if (!athleteId) {
      navigate('/manage-athlete');
      return;
    }
    const fetchProfileData = async () => {
      const response = await AthleteSer.getAthletePersonalDetails(athleteId);
      setPersonalDetails(response?.data);
      setIsActive((response?.data?.status ==="active")?true:false);
      addDeleteGetLocalStorage(STORAGE.SPORTS, ((response?.data?.sport_cat)?(response?.data?.sport_cat.map(Number)):'[]'), "add", "single");
      calculateAge(response?.data?.dob);
    }
    fetchProfileData();
  }, [id]);
  const showEditEmailModal = () => {
    setIsEditEmailModalOpen(true);
  };
  const handleEditEmailOk = () => {
    setIsEditEmailModalOpen(false);
  };
  const handleEditEmailCancel = () => {
    setIsEditEmailModalOpen(false);
  };

  const [fileList, setFileList] = useState([
    {
      uid: "-1",
      name: "image.png",
      status: "done",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
  ]);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    if (isNaN(birthDate.getTime())) {
      return 0;
    }
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }
    setAge(age >= 0 ? age : 0);
    //return age >= 0 ? age : 0;
  };
  const handleToggle = async (checked) => {
    try {
      let status = (checked)?'active':'inactive';
      const response = await AthleteSer.updateAthleteStatus(athleteId, status);
      setIsActive(checked);
      showAlert(response?.message, "success");
    } catch (error) {
      navigate('/manage-athlete');
      let msg = AthleteSer.errorMessage(error);
      showAlert(msg, "error");
    }
  };
  return (
    <>
      <Card>
        <Row className="flex-col gap-y-4">
          <Row className="gap-y-2 flex-col">
            <div className="flex justify-between items-center mb-3">
              <div className="font-semibold text-lg">Athlete Info</div>
            </div>
            <Row>
              <Col span={3}>
                <div className="mr-4">
                  <div className="font-semibold text-base">
                    Profile Picture:
                  </div>
                  <div className="w-full  max-w-[110px] h-[110px] rounded-lg overflow-hidden mt-2">
                  <Image
                    width={90}
                    src={(personalDetails?.profile_image)?(personalDetails?.profile_image):"/images/user.png"}
                  />
                  </div>
                  {/* <Upload
                    listType="picture-circle"
                    fileList={fileList}
                    onPreview={handlePreview}
                    maxCount={1}
                  >
                    {fileList.length >= 1 ? null : uploadButton}
                  </Upload>
                  {previewImage && (
                    <Image
                      wrapperStyle={{
                        display: "none",
                      }}
                      preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) =>
                          !visible && setPreviewImage(""),
                      }}
                      src={previewImage}
                    />
                  )} */}
                </div>
              </Col>
              <Col span={21}>
                <Row className="grid grid-cols-2 gap-4">
                  <Col>
                    <div>
                      <div className="font-semibold text-base">Email:</div>
                      <div className="text-sm flex items-center gap-2">
                        {personalDetails?.email}
                        {/* <EditOutlined
                          className="mx-1.5 cursor-pointer"
                          onClick={showEditEmailModal}
                        /> */}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <div className="font-semibold text-base">
                        Registered Date:
                      </div>
                      <div className="text-sm">{moment(personalDetails?.created_at).format('MM/DD/YYYY')}</div>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <div className="font-semibold text-base">Athlete ID:</div>
                      <div className="text-sm">{personalDetails?.id}</div>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <div className="font-semibold text-base">Status:</div>
                      <Switch checked={isActive} onChange={handleToggle} />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
          <Divider className="my-0" />
          <Row className="gap-y-2 flex-col">
            <div className="font-semibold text-lg">Personal Information:</div>
            <Row className="grid grid-cols-4 gap-4">
              <Col>
                <div>
                  <div className="font-semibold text-base">Full Name:</div>
                  <div className="text-sm">{personalDetails?.first_name} {personalDetails?.last_name}</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="font-semibold text-base">Phone no:</div>
                  <div className="text-sm">+{(personalDetails?.country_code)?(personalDetails?.country_code):'1'} {personalDetails.mobile}</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="font-semibold text-base">Gender:</div>
                  <div className="text-sm">{personalDetails?.gender}</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="font-semibold text-base">Height:</div>
                  <div className="text-sm">{personalDetails?.height} {personalDetails?.height_unit}</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="font-semibold text-base">Weight:</div>
                  <div className="text-sm">{personalDetails?.weight} {personalDetails?.weight_unit}</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="font-semibold text-base">DOB:</div>
                  <div className="text-sm">{moment(personalDetails?.dob).format('MM/DD/YYYY')}</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="font-semibold text-base">Age:</div>
                  <div className="text-sm">{age}</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="font-semibold text-base">
                    Parent Email address 1:
                  </div>
                  <div className="text-sm">{personalDetails?.parent_email_1}</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="font-semibold text-base">
                    Parent Email address 2:
                  </div>
                  <div className="text-sm">{(personalDetails?.parent_email_2)?(personalDetails?.parent_email_2):'-'}</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="font-semibold text-base">
                    Coach Email address:
                  </div>
                  <div className="text-sm">{(personalDetails?.coach_email)?(personalDetails?.coach_email):'-'}</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="font-semibold text-base">Citizenship:</div>
                  <div className="text-sm">{personalDetails?.citizen_ship}</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="font-semibold text-base">
                    Committed school:
                  </div>
                  <div className="text-sm">{personalDetails?.is_committed_school}</div>
                </div>
              </Col>
              {personalDetails?.is_committed_school !=='No' && (
              <Col>
                <div>
                  <div className="font-semibold text-base">
                    Committed high school name:
                  </div>
                  <div className="text-sm">{(personalDetails?.committed_school)?(personalDetails?.committed_school):'-'}</div>
                </div>
              </Col>
              )}
            </Row>
          </Row>
          <Divider className="my-0" />
          <Row className="gap-y-2 flex-col">
            <div className="font-semibold text-lg">
              Permanent Address details:
            </div>
            <Row className="grid grid-cols-4 gap-4">
              <Col>
                <div>
                  <div className="font-semibold text-base">Country:</div>
                  <div className="text-sm">{personalDetails?.user_address?.country}</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="font-semibold text-base">City:</div>
                  <div className="text-sm">{personalDetails?.user_address?.city}</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="font-semibold text-base">State:</div>
                  <div className="text-sm">{personalDetails?.user_address?.state}</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="font-semibold text-base">Zip Code:</div>
                  <div className="text-sm">{personalDetails?.user_address?.zip_code}</div>
                </div>
              </Col>
              <Col>
                <div>
                  <div className="font-semibold text-base">Address:</div>
                  <div className="text-sm">{personalDetails?.user_address?.address}</div>
                </div>
              </Col>
            </Row>
            <div className="font-semibold text-lg mt-2">Travel/Club team:</div>
            <Row className="grid grid-cols-4 gap-4">
            {personalDetails?.travel_team?.length > 0 ? (
             personalDetails?.travel_team?.map((team, index) => (
              <Col key={index}>
                <div>
                  <div className="font-semibold text-base">Team {index+1}:</div>
                  <div className="text-sm">{team}</div>
                </div>
              </Col>
            ))
            ) : (
              "-"
            )}
            </Row>
          </Row>
          <Divider className="my-0" />
          <Row className="gap-y-2 flex-col">
            <div className="font-semibold text-lg">Social Media Links:</div>
            <Row className="grid grid-cols-4 gap-4">
            {personalDetails?.social_media_link?.map((social, index) => (
              <Col key={index}>
                <div>
                  <div className="font-semibold text-base">{social.name}:</div>
                  <a className="text-sm" href={social.link}>
                   {(social.link)?(social.link):'-'}
                  </a>
                </div>
              </Col>
            ))}
            </Row>
          </Row>
          <Divider className="my-0" />
          <Row className="gap-y-2 flex-col">
            <div className="font-semibold text-lg">About Athlete:</div>
            <Row className="grid grid-cols-1 gap-4">
              <Col>
                <div>
                  <div className="text-sm">
                  {(personalDetails?.user_bio)?(personalDetails?.user_bio):'-'}
                  </div>
                </div>
              </Col>
            </Row>
          </Row>
        </Row>
      </Card>

      <EditEmailAthlete
        isOpen={isEditEmailModalOpen}
        onOk={handleEditEmailOk}
        onCancel={handleEditEmailCancel}
      />
    </>
  );
};
export default PersonalInformation;
