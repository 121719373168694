import { useState, useEffect } from "react";
import { Avatar, Card, Col, Divider, Row, Typography, Carousel, Tooltip, Switch } from "antd";
import { LeftOutlined, ExclamationCircleOutlined,EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import SortingArrow from "../../Common/SortingArrow";
import PaginationComponent from "../../Common/Pagination";
import CollegeService from "../../service/CollegeService";
import { useAlert } from "../../contexts/AlertProvider";
import { useLoader } from "../../contexts/LoadingProvider";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import moment from 'moment';
const CollegeSer = new CollegeService();
const { Meta } = Card;
const ViewCollege = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [collegeData, setCollegeData] = useState("");
  const { setLoading } = useLoader();
  const { showAlert } = useAlert();
  const [isActive, setIsActive] = useState(false);
  const [filters, setFilters] = useState({ collegeId:0, page: 1, limit:10,sort:'',order:'' });
  const [collegeCoach, setCollegeCoach] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  useEffect(() => {
    if (slug) {
      const fetchProfileData = async () => {
        const response = await CollegeSer.getCollegeDetails(slug);
        if(response?.data?.status ==='deleted'){
          showAlert("College status id deleted", "success");
          navigate('/manage-college');
          return;
        }
        setCollegeData(response?.data);
        setIsActive((response?.data?.status ==="active")?true:false);
        setFilters({ ...filters, collegeId:response?.data?.id });
      };
      fetchProfileData();
    }
  }, [slug]);

  useEffect(() => {
    if (filters.collegeId !== 0) {
      const filterCoach = async () => {
        const response = await CollegeSer.getCollegeCoach(filters);
        setCollegeCoach(response?.data);
      };
      filterCoach();
    }
  }, [filters]);
  const handlePageChange = (page, size) => {
    setFilters({ ...filters, page, limit: size });
  };
  const handleSort = (field, key) => {
    setFilters({ ...filters, page:1, sort:field, order: key });
  };

  const handleToggle = async (checked) => {
    setLoading(true);
    try {
      let status = (checked)?'active':'inactive';
      const response = await CollegeSer.updateCollegeStatus(collegeData?.id, status);
      setIsActive(checked);
      showAlert(response?.message, "success");
    } catch (error) {
      navigate('/manage-college');
      let msg = CollegeSer.errorMessage(error);
      showAlert(msg, "error");
    }
    setLoading(false);
  };

  const getSportCategoryNames = (data,type) => {
    if(type==='tool'){
      return data?.slice(2)?.map(item => `${item.name} (${item.gender === 'male' ? 'M' : 'F'})`).join(", ");
    }else{
      return data?.slice(0, 2)?.map(item => `${item.name} (${item.gender === 'male' ? 'M' : 'F'})`).join(", ")
    }
  };

  const handleDeleteOk =async () => {
    try {
    let isDeleteId = collegeData?.id;
    const response = await CollegeSer.updateCollegeStatus(isDeleteId,'deleted');
    setIsDeleteModalOpen(false);
    navigate('/manage-college');
    showAlert(response?.message, "success");
    } catch (error) {
      let msg = CollegeSer.errorMessage(error);
      showAlert(msg, "error");
    }
  };
  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };
  return (
    <>
      <Card>

      <div className="flex justify-between items-center mb-4 gap-3">
          <div>
            <Typography
              className="text-xl font-semibold"
              align={"left"}
            
            >
              <LeftOutlined
                className="text=lg text-black mr-2 cursor-pointer"
                onClick={() => navigate(-1)}
              />
              View College Details
            </Typography>
          </div>
          <div>
          <Tooltip title="Edit">
                  <EditOutlined
                    onClick={() => navigate(`/create-college/${slug}`)}
                    className="mx-1.5 cursor-pointer"
                  />
                </Tooltip>
                <Tooltip title="Delete">
                  <DeleteOutlined
                    className="mx-1.5 cursor-pointer"
                    onClick={() => setIsDeleteModalOpen(true)}
                  />
                </Tooltip>
              </div>

        </div>
      <Divider />
        <div >
         <div className="max-w-[250px]">
         <Carousel arrows autoplay>
          {collegeData?.images?.map((img) =>(
            <img
              src={img}
              alt="college-banner"
              className="w-full h-[250px] object-cover rounded-lg"
            />
          ))}
          </Carousel>
         </div>
          <Row className="gap-5 mt-6">
          <Col span={10}>
              <div className="">
                <div className="font-semibold text-base">College ID :</div>
                <div className="text-sm">#{collegeData.id}</div>
              </div>
            </Col>
            <Col span={10}>
              <div className="">
                <div className="font-semibold text-base">College Name :</div>
                <div className="text-sm">{collegeData.name}</div>
              </div>
            </Col>
            <Col span={10}>
              <div className="">
                <div className="font-semibold text-base">Created Date :</div>
                <div className="text-sm">{moment(collegeData?.created_at).format('MM/DD/YYYY')}</div>
              </div>
            </Col>
            <Col span={10}>
              <div className="">
                <div className="font-semibold text-base">Division :</div>
                <div className="text-sm">{collegeData?.division_id?.map(item => item.name).join(", ")}</div>
              </div>
            </Col>
            {/* <Col span={10}>
              <div className="">
                <div className="font-semibold text-base">Gender applicable :</div>
                <div className="text-sm">{collegeData?.gender}</div>
              </div>
            </Col> */}
            <Col span={10}>
              <div className="">
                <div className="font-semibold text-base">Sports :</div>
                <div className="text-sm">{collegeData?.sport_category_id?.map(item => `${item.name} (${item.gender === 'male' ? 'M' : 'F'})`).join(", ")}
                </div>
              </div>
            </Col>
            <Col span={10}>
              <div className="">
                <div className="font-semibold text-base">Website URL :</div>
                <div className="text-sm">{collegeData.website_url}</div>
              </div>
            </Col>
            <Col span={10}>
              <div className="">
                <div className="font-semibold text-base">Status :</div>
                <div className="text-sm">   <Switch checked={isActive} onChange={handleToggle} /></div>
              </div>
            </Col>
            <Col>
              <div className="">
                <div className="font-semibold text-base">Description :</div>
                <div className="text-sm">
                  {collegeData?.description}
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div className="mt-3">
                <div className="font-semibold text-base">Address :</div>
                <div className="text-sm">{collegeData?.address}</div>
              </div>
            </Col>
            <Col span={5}>
              <div className="">
                <div className="font-semibold text-base">Country :</div>
                <div className="text-sm">{collegeData?.country}</div>
              </div>
            </Col>
            <Col span={5}>
              <div className="">
                <div className="font-semibold text-base">State/Province :</div>
                <div className="text-sm">{collegeData?.state}</div>
              </div>
            </Col>
            <Col span={5}>
              <div className="">
                <div className="font-semibold text-base">City :</div>
                <div className="text-sm">{collegeData?.city}</div>
              </div>
            </Col>
            <Col span={5}>
              <div className="">
                <div className="font-semibold text-base">Zipcode :</div>
                <div className="text-sm">{collegeData?.zipcode}</div>
              </div>
            </Col>
            <Col span={5}>
              <div className="">
                <div className="font-semibold text-base">Contact Number :</div>
                <div className="text-sm">{collegeData?.contact_number}</div>
              </div>
            </Col>
            <Col span={5}>
              <div className="">
                <div className="font-semibold text-base">Email ID :</div>
                <div className="text-sm">{collegeData?.email_address}</div>
              </div>
            </Col>
           
           
            <Col span={24}>
              <div className="mt-3">
                <div className="font-semibold text-base">
                  Eligibility Criteria Details
                </div>
              </div>
            </Col>
            <Col span={5}>
              <div className="">
                <div className="font-semibold text-base">College Type :</div>
                <div className="text-sm capitalize">{collegeData?.college_type}</div>
              </div>
            </Col>
            <Col span={5}>
              <div className="">
                <div className="font-semibold text-base">Conference :</div>
                <div className="text-sm">{collegeData?.conference}</div>
              </div>
            </Col>
            <Col span={5}>
              <div className="">
                <div className="font-semibold text-base">Enrolment :</div>
                <div className="text-sm">{collegeData?.enrollment}</div>
              </div>
            </Col>
            <Col span={5}>
              <div className="">
                <div className="font-semibold text-base">
                  Number of Under Graduates :
                </div>
                <div className="text-sm">{collegeData?.under_graduate}</div>
              </div>
            </Col>
            <Col span={5}>
              <div className="">
                <div className="font-semibold text-base">Majors offered :</div>
                <div className="text-sm">{collegeData?.major_offered}</div>
              </div>
            </Col>
            <Col span={5}>
              <div className="">
                <div className="font-semibold text-base">Average GPA :</div>
                <div className="text-sm">{collegeData?.average_gpa}</div>
              </div>
            </Col>
            <Col span={5}>
              <div className="">
                <div className="font-semibold text-base">SAT/ACT required :</div>
                <div className="text-sm">{(collegeData?.sat_act_status===1)?'Yes':'No'}</div>
              </div>
            </Col>
            <Col span={5}>
              <div className="">
                <div className="font-semibold text-base">SAT score range :</div>
                <div className="text-sm">{collegeData?.sat_score}</div>
              </div>
            </Col>
            <Col span={5}>
              <div className="">
                <div className="font-semibold text-base">ACT composite score range :</div>
                <div className="text-sm">{collegeData?.act_score}</div>
              </div>
            </Col>
            <Col span={24}>
              <div className="mt-3">
                <div className="font-semibold text-base">Expenses</div>
              </div>
            </Col>
            <Col span={10}>
              <div className="">
                <div className="font-semibold text-base">
                  Base Tuition (in state) :
                </div>
                <div className="text-sm">{collegeData?.base_tution_state}</div>
              </div>
            </Col>
            <Col span={10}>
              <div className="">
                <div className="font-semibold text-base">
                  Estimated Total Expenses (in state) :
                </div>
                <div className="text-sm">
                {collegeData?.total_expenses_state}
                </div>
              </div>
            </Col>
            <Col span={10}>
              <div className="">
                <div className="font-semibold text-base">
                  Base Tuition (out of state) :
                </div>
                <div className="text-sm">{collegeData?.base_tution_out_state}</div>
              </div>
            </Col>
            <Col span={10}>
              <div className="">
                <div className="font-semibold text-base">
                  Estimated Total Expenses (out of state) :
                </div>
                <div className="text-sm">
                 {collegeData?.total_expenses_out_state}
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div className="mt-3">
                <div className="font-semibold text-base">
                  Contact Person Details 
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div className="grid grid-cols-2 gap-5">
              {collegeData?.college_contacts?.map((contact) =>(
                <Card>
                  <Meta
                    avatar={
                      <Avatar src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
                    }
                    title={
                      (() => {
                        const title = `${contact.first_name} ${contact.last_name}`; 
                        const words = title.split(" ");
                        return words.length > 15 ? words.slice(0, 50).join(" ") + "..." : title;
                      })()
                    }
                    description={
                      <>
                        <p>{contact?.contact_number}</p>
                        <p className="break-all">{contact?.email_id}</p>
                      </>
                    }
                  />
                </Card>
              ))}
              </div>
            </Col>
            <Col span={24}>
              <div className="mt-3">
                <div className="font-semibold text-base">
                  Associate Coaches Details
                </div>
              </div>
            </Col>
            <Col span={24}>
              <table className="w-full  text-left	 text-sm  text-gray-500">
                <thead className=" text-gray-700 capitalize text-xs	 bg-gray-50">
                  <tr>
                    <th scope="col" className="px-3 py-3 whitespace-nowrap">
                      Coach ID <SortingArrow onSort={handleSort} field="id"/>
                    </th>
                    <th scope="col" className="px-3 py-3">
                      Coach name <SortingArrow onSort={handleSort} field="first_name"/>
                    </th>
                    <th scope="col" className="px-3 py-3">
                      Title <SortingArrow onSort={handleSort} field="current_position"/>
                    </th>
                    <th scope="col" className="px-3 py-3">
                      Email
                      <SortingArrow onSort={handleSort} field="email_address"/>
                    </th>
                    <th scope="col" className="px-3 py-3">
                      Sports
                    </th>
                    <th scope="col" className="px-3 py-3">
                      Associated Date <SortingArrow onSort={handleSort} field="created_at"/>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-center whitespace-nowrap vertical-top"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                {collegeCoach?.rows?.length > 0 ? (
                  collegeCoach.rows.map((coach, index) => (
                    <tr key={index} className="bg-white border-b" onClick={() => navigate(`/coach-detail/${coach.id}`)} >
                      <th
                        scope="row"
                        className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top cursor-pointer"
                      
                      >
                        #{coach?.id}
                      </th>
                      <td className="px-3 py-4 vertical-top">{coach?.first_name} {coach?.last_name}</td>
                      <td className="px-3 py-4 vertical-top">{coach?.current_position}</td>
                      <td className="px-3 py-4 vertical-top">{coach?.email_address}</td>
                      <td className="px-3 py-4 vertical-top">{getSportCategoryNames(coach?.sports_category,"category")}{" "}
                        {coach.sports_category?.length > 2 && (
                          <Tooltip title= {getSportCategoryNames(coach.sports_category,"tool")}>
                            <ExclamationCircleOutlined className="mx-1.5 cursor-pointer" />
                          </Tooltip>
                        )}</td>
                      <td className="px-3 py-4 vertical-top">{moment(coach?.created_at).format('MM/DD/YYYY')}</td>
                      <td className="px-3 py-4 text-center whitespace-nowrap vertical-top">
                        <Tooltip title="View coach">
                          <EyeOutlined
                            className="mx-1.5 cursor-pointer"
                            onClick={() => navigate(`/coach-detail/${coach.id}`)}
                          />
                        </Tooltip>
                      </td>
                    </tr>
                  ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center px-3 py-4 text-gray-500">
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="flex justify-between items-center">
                <div className="font-semibold text-base">
                  Total count : <span>{collegeCoach?.count}</span>
                </div>
                {collegeCoach?.count > 10 && (
                  <PaginationComponent
                    currentPage={filters.page}
                    total={collegeCoach?.count}
                    pageSize={filters.limit}
                    onPageChange={handlePageChange} />
                  )}
              </div>
            </Col>
          </Row>
        </div>
      </Card>
      {/* delete modal */}
      <ConfirmationModal
        ConfirmationHeading="Delete"
        ConfirmationParagraph="Are you sure you want to delete this College?"
        isOpen={isDeleteModalOpen}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
    </>
  );
};
export default ViewCollege;
