import { useState, useEffect } from "react";
import {
  Card,
  Col,
  Form,
  Row,
  Select,
  Collapse,
  Input,
  Button,
  Upload,
  Image,
} from "antd";
import {
  PlusOutlined,
  CloseCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { STORAGE } from "../../../Enum";
import addDeleteGetLocalStorage from "../../../prototype/addDeleteGetLocalStorage";
import AthleteService from "../../../service/AthleteService";
import { Config } from "../../../constants/Config";
import { useAlert } from "../../../contexts/AlertProvider";
import { getFileNameWithRandomNumber } from "../../../utils/validation";
import { useLoader } from "../../../contexts/LoadingProvider";
import { decodeId } from '../../../utils/encoding';
import CommonService from "../../../service/CommonService";
const CommonSer = new CommonService();

const { Option } = Select;
const AthleteSer = new AthleteService();

const SportsDetails = () => {
  const { id } = useParams();
  const athleteId = decodeId(id);
  const { setLoading } = useLoader();
  const [categoryFields, setCategoryFields] = useState([]);
  const { showAlert } = useAlert();

  const allowedTypes = ["application/pdf"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let sports = addDeleteGetLocalStorage(
          STORAGE.SPORTS,
          {},
          "get",
          "single"
        );
        const response = await AthleteSer.getAthleteSportsDetails(sports, athleteId);
        const updatedData = response.data.map((category) => ({
          ...category,
          user_id: parseInt(athleteId),
          sport_id: category?.fields[0]?.sports_fields_values[0]?.sports_id
            ? category?.fields[0]?.sports_fields_values[0]?.sports_id
            : 0,
          id_sport: category?.sportsData?.id ? category?.sportsData?.id : 0,
          recruit_profile_link: [],
          travel_schedule:
            category.sportsData && category.sportsData.travel_schedule
              ? category.sportsData.travel_schedule
              : [],
          analytics_data:
            category.sportsData && category.sportsData.analytics_data
              ? category.sportsData.analytics_data
              : [],
          maxpreps_link: [],
          fields: category.fields.map((field, index) => {
            let value = "";
            let other_value = field.other_option ? field?.options[0] : "";

            if (
              field.sports_fields_values.length > 0 &&
              field.sports_fields_values[0] !== null
            ) {
              value = field.sports_fields_values[0].field_value;
              other_value = field.sports_fields_values[0].other_value;
            }
            return {
              ...field,
              value: value,
              other_value: other_value,
            };
          }),
          recruit_profile_link_tab:
            category.sportsData && category.sportsData.recruit_profile_link
              ? category.sportsData.recruit_profile_link.map((link) => ({
                  value: link,
                }))
              : [],
          maxpreps_tab:
            category.sportsData && category.sportsData.maxpreps_link
              ? category.sportsData.maxpreps_link.map((link) => ({
                  value: link,
                }))
              : [],
        }));
        setCategoryFields(updatedData);
        setLoading(false);
      } catch (error) {
        //let msg = ProfileSer.errorMessage(error);
        //showAlert(msg, "error");
      }
    };
    fetchData();
  }, [athleteId]);

  const handleAddLink = (categoryIndex, fieldType) => {
    console.log(categoryIndex, fieldType, "add");
    setCategoryFields((prevState) => {
      const newFields = [...prevState];
      if (fieldType === "profilelink") {
        newFields[categoryIndex].recruit_profile_link_tab.push({ value: "" });
      } else {
        newFields[categoryIndex].maxpreps_tab.push({ value: "" });
      }
      return newFields;
    });
  };

  const handleRemoveLink = (categoryIndex, linkIndex, fieldType) => {
    console.log(categoryIndex, linkIndex, fieldType, "delete");
    setCategoryFields((prevState) => {
      const newFields = [...prevState];
      if (fieldType === "profilelink") {
        newFields[categoryIndex].recruit_profile_link_tab = newFields[
          categoryIndex
        ].recruit_profile_link_tab.filter((_, idx) => idx !== linkIndex);
      } else {
        newFields[categoryIndex].maxpreps_tab = newFields[
          categoryIndex
        ].maxpreps_tab.filter((_, idx) => idx !== linkIndex);
      }
      return newFields;
    });
  };

  const handleInputLinkChange = (
    categoryIndex,
    itemIndex,
    arrayName,
    newValue
  ) => {
    
    setCategoryFields((prevState) => {
      const newFields = [...prevState];
      if (arrayName === "profilelink") {
        newFields[categoryIndex].recruit_profile_link_tab[itemIndex].value =
          newValue;
      } else {
        newFields[categoryIndex].maxpreps_tab[itemIndex].value = newValue;
      }

      return newFields;
    });
  };

  const handleChange = (categoryIndex, fieldIndex, newValue) => {
    setCategoryFields((prevData) => {
      const updatedCategories = [...prevData];

      updatedCategories[categoryIndex].fields[fieldIndex] = {
        ...updatedCategories[categoryIndex].fields[fieldIndex],
        value: newValue,
      };

      return updatedCategories;
    });
  };

  const handleSelectChange = (
    categoryIndex,
    fieldIndex,
    newValue,
    fieldType = "option"
  ) => {
    setCategoryFields((prevData) => {
      const updatedCategories = [...prevData];
      if (fieldType === "option") {
        updatedCategories[categoryIndex].fields[fieldIndex] = {
          ...updatedCategories[categoryIndex].fields[fieldIndex],
          value: newValue,
        };
      } else {
        updatedCategories[categoryIndex].fields[fieldIndex] = {
          ...updatedCategories[categoryIndex].fields[fieldIndex],
          other_value: newValue,
        };
      }
      return updatedCategories;
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const cleanedCategoryFields = categoryFields.map((category) => {
        const { name, image, ...restCategory } = category;
        return {
          ...restCategory,
          recruit_profile_link: category.recruit_profile_link_tab.map(
            (item) => item.value
          ),
          maxpreps_link: category.maxpreps_tab.map((item) => item.value),
          fields: category.fields.map((field) => {
            const {
              category_id,
              field_label,
              field_name,
              field_type,
              options,
              other_option,
              field_order,
              status,
              created_at,
              updated_at,
              updated_by,
              ...restField
            } = field;
            return restField;
          }),
        };
      });

      const finalData = {
        data: cleanedCategoryFields,
      };

      let result = await AthleteSer.updateSportCategoryDetails(finalData);
      showAlert(result?.message, "success");
      //let refdata = await refreshToken(decodedToken.email);
      //saveToken(refdata);
    } catch (error) {
      let msg = AthleteSer.errorMessage(error);
      showAlert(msg, "error");
      //hideSpinner();
    }
    setLoading(false);
  };

  const handleFileChange = (event, fieldName, categoryIndex) => {
    //const selectedFiles = Array.from(event.target.files);
    const selectedFile = event.target.files[0];
    const maxSizeInMB = 5; // 5MB limit per file
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
    //console.log(selectedFiles.length,"sdsdsds"); return;
    if (selectedFile) {
      if (allowedTypes.includes(selectedFile.type)) {
        if (selectedFile.size <= maxSizeInBytes) {
          //uploadFileToS3Bucket(selectedFile, type)
          uploadFileToS3Bucket(selectedFile, fieldName, categoryIndex);
        } else {
          showAlert("File size exceeds 5MB limit.", "error");
        }
      } else {
        showAlert("Invalid file type.", "error");
      }
    }

    // selectedFiles.forEach((file) => {
    //   if (file) {
    //     if (allowedTypes.includes(file.type)) {
    //       if (file.size <= maxSizeInBytes) {
    //         uploadFileToS3Bucket(file, fieldName, categoryIndex);
    //       } else {
    //         showAlert(`File size exceeds 5MB limit: ${file.name}`, "error");
    //       }
    //     } else {
    //       showAlert(
    //         `Invalid file type for file: ${file.name}. Only PDF and image files are allowed.`,
    //         "error"
    //       );
    //     }
    //   }
    // });
  };

  const uploadFileToS3Bucket = async (file, fieldName, categoryIndex) => {
    setLoading(true);
    if (!file) {
      showAlert("Please select a file first!", "error");
      setLoading(false);
      return;
    }
    //setUploading(true);
    const newFileName = getFileNameWithRandomNumber(file?.name);
    try {
      // await s3.send(new PutObjectCommand(params));
      // const publicUrl = `https://${Config?.s3BucketName}.s3.${Config?.s3Region}.amazonaws.com/${newFileName}`;
      const renamedFile = new File([file], newFileName, { type: file.type });
      let payload = {
        user_id: athleteId,
        image: renamedFile,
        field: "",
        page: "athlete_sport_details",
        type: "pdf"
      }
      const formData = new FormData();
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });
      let response = await CommonSer.uploadFile(formData);
      const publicUrl = response?.data;
      setCategoryFields((prevState) => {
        const newFields = [...prevState];
        if (fieldName === "travel_schedule") {
          if (!newFields[categoryIndex].travel_schedule.includes(publicUrl)) {
            newFields[categoryIndex].travel_schedule.push(publicUrl);
          }
        } else {
          if (!newFields[categoryIndex].analytics_data.includes(publicUrl)) {
            newFields[categoryIndex].analytics_data.push(publicUrl);
          }
        }
        return newFields;
      });
      //setUploading(false);
    } catch (error) {
      showAlert(error.message, "error");
      //setUploading(false);
    }
    setLoading(false);
  };

  const deleteFileFromS3BucketByUrl = async (fileUrl, fieldType, sport_id) => {
    const key = extractKeyFromUrl(fileUrl);
    try {
      let payload = {
        user_id: athleteId,
        url: fileUrl,
        field: fieldType,
        type: "pdf",
        sport_id: sport_id,
        page: "athlete_sport_details"
      };
      let response = await CommonSer.deleteFile(payload);
    } catch (error) {
      showAlert(error.message, "error");
    }
  };

  const handleRemovePdf = (fileUrl, linkIndex, categoryIndex, fieldType, sport_id) => {
    deleteFileFromS3BucketByUrl(fileUrl,fieldType, sport_id);
    setCategoryFields((prevState) => {
      const newFields = [...prevState];
      if (fieldType === "travel_schedule") {
        newFields[categoryIndex].travel_schedule = newFields[
          categoryIndex
        ].travel_schedule.filter((_, i) => i !== linkIndex);
      } else {
        newFields[categoryIndex].analytics_data = newFields[
          categoryIndex
        ].analytics_data.filter((_, i) => i !== linkIndex);
      }
      return newFields;
    });
  };

  const extractKeyFromUrl = (url) => {
    const urlParts = url.split("/");
    return urlParts.slice(3).join("/");
  };

  return (
    <>
      <Card>
        <div>
          <div className="font-semibold text-base mb-[10px]">
            Provide sport details
          </div>
          <div className="flex flex-col gap-4">
            <Collapse
              collapsible="header"
              expandIconPosition="right"
              items={categoryFields.map((category, index) => ({
                key: category.key,
                label: (
                  <div className="flex gap-3 items-center text-[#1D2137] text-sm font-semibold">
                    <img
                      src={`${category.image ? Config.imagePATH + category.image : '/images/sport.svg'}`}
                      alt="college-banner"
                      className="h-12 w-12 object-cover rounded overflow-hidden"
                    />
                    {category.name}
                  </div>
                ),
                children: (
                  <Row>
                    {category?.fields?.map((field, fieldIndex) => (
                      <>
                        {field.field_type === "select" ? (
                          <Col key={fieldIndex} span={10}>
                            <div className="mr-4 mb-6">
                              <label>{field.field_label}</label>
                              <Select
                              size="large"
                                key={fieldIndex}
                                value={field?.value}
                                style={{ width: "100%" }}
                                onChange={(value) =>
                                  handleSelectChange(index, fieldIndex, value)
                                }
                              >
                                {field.options.map((opt, idx) => (
                                  <Option key={idx} value={opt}>
                                    {opt}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </Col>
                        ) : (
                          <Col key={fieldIndex} span={10}>
                            <div className="mr-4 mb-6">
                              <label>{field.field_label}</label>
                              <div style={{ display: 'flex', width: '100%' }}>
                              <Input
                              size="large"
                              style={{ width: field.other_option ? 'calc(100% - 80px)' : '100%' }}
                              type={field.field_type}
                                value={field.value}
                                onChange={(e) =>
                                  handleChange(
                                    index,
                                    fieldIndex,
                                    e.target.value
                                  )
                                }
                              />
                              {field.other_option ? (
                                    <Select
                                      style={{ width: 80, height:40 }}
                                      value={field?.other_value}
                                      onChange={(value) =>
                                        handleSelectChange(index, fieldIndex, value, "otheroption")
                                      }
                                    >
                                      {field.options.map((opt, idx) => (
                                        <Option key={idx} value={opt}>
                                          {opt}
                                        </Option>
                                      ))}
                                    </Select>
                                  ) : null
                              }
                              </div>
                            </div>
                          </Col>
                        )}
                      </>
                    ))}
                    <Col key={`profile${index}`} span={24}>
                      <div className="text-base font-medium text-black flex items-center gap-4 mb-2">
                        Recruiting Profile Link:
                        <div
                          type="primary"
                          className="mr-4 py-0 flex items-center justify-center bg-blue-600 text-white w-6 h-6 rounded"
                          onClick={() => handleAddLink(index, "profilelink")}
                        >
                          <PlusOutlined className="" />
                        </div>
                      </div>
                    </Col>
                    {category.recruit_profile_link_tab.map(
                      (link, linkIndex) => (
                        <Col key={linkIndex} span={20}>
                          <div className="mr-4 mb-6 flex items-center gap-3">
                            <Input size="large" value={link.value}   onChange={(e) =>
                            handleInputLinkChange(
                              index,
                              linkIndex,
                              "profilelink",
                              e.target.value
                            )
                          }/>

                            <CloseCircleOutlined
                              onClick={() =>
                                handleRemoveLink(
                                  index,
                                  linkIndex,
                                  "profilelink"
                                )
                              }
                              style={{
                                fontSize: "24px",
                                color: "red",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </Col>
                      )
                    )}

                      <Col key={`travel${index}`} span={10}>
                        <div className="text-base font-medium text-black flex items-center gap-4 mb-2">
                          Travel Schedule:
                        </div>
                        <div className="hide-upload mr-4">
                          <Upload
                            className="upload-lang"
                            showUploadList={false}
                            multiple={true}
                            accept=".pdf"
                            beforeUpload={(file) => {
                              handleFileChange(
                                { target: { files: [file] } },
                                "travel_schedule",
                                index
                              );
                              return false;
                            }}
                          >
                            <Button
                              icon={<UploadOutlined />}
                              className="w-full h-[37px]"
                            >
                              Click to Upload
                            </Button>
                          </Upload>
                          <div className="flex items-center mt-2 gap-2 flex-wrap">
                            {category.travel_schedule.map(
                              (link, indexlinkIndex) => (
                                <>
                                <div key={indexlinkIndex} className="w-[80px] h-[80px] rounded-lg bg-[#EEEEEE] flex items-center justify-center relative mt-4">
                                  <Image
                                    src="/images/pdf-icon.png"
                                    alt="pdf-icon"
                                    shadow="none"
                                    radius="none"
                                    width={"35px"}
                                    preview={false}
                                    height={"35px"}
                                    onClick={() =>{
                                      if (link){  
                                        window.open(link, "_blank");
                                      }
                                    }}
                                  />
                                  <div className="absolute right-2 top-2 z-10 cursor-pointer">
                                    <Image
                                      src="/images/cancel.svg"
                                      alt="remove"
                                      shadow="none"
                                      radius="none"
                                      width={"15px"}
                                      height={"15px"}
                                      onClick={() =>
                                        handleRemovePdf(
                                          link,
                                          indexlinkIndex,
                                          index,
                                          "travel_schedule",
                                          category?.id_sport
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                </>
                              )
                            )}
                          </div>
                          
                        </div>
                      </Col>
                      <Col key={`analytical${index}`} span={10}>
                        <div className="text-base font-medium text-black flex items-center gap-4 mb-2">
                          Analytical Data:
                        </div>
                        <div className="hide-upload mr-4">
                          <Upload
                            className="upload-lang"
                            showUploadList={false}
                            multiple={true}
                            accept=".pdf"
                            beforeUpload={(file) => {
                              handleFileChange(
                                { target: { files: [file] } },
                                "analytics_data",
                                index
                              );
                              return false;
                            }}
                          >
                            <Button
                              icon={<UploadOutlined />}
                              className="w-full h-[37px]"
                            >
                              Click to Upload
                            </Button>
                          </Upload>
                          <div className="flex items-center mt-2 gap-2 flex-wrap">
                            {category.analytics_data.map(
                              (link, indexlinkIndex) => (
                                <>
                                <div key={indexlinkIndex} className="w-[80px] h-[80px] rounded-lg bg-[#EEEEEE] flex items-center justify-center relative mt-4">
                                  <Image
                                    src="/images/pdf-icon.png"
                                    alt="pdf-icon"
                                    shadow="none"
                                    radius="none"
                                    width={"35px"}
                                    height={"35px"}
                                    preview={false}
                                    onClick={() =>{
                                      if (link){  
                                        window.open(link, "_blank");
                                      }
                                    }}
                                  />
                                  <div className="absolute right-2 top-2 z-10 cursor-pointer">
                                    <Image
                                      src="/images/cancel.svg"
                                      alt="remove"
                                      shadow="none"
                                      radius="none"
                                      width={"15px"}
                                      height={"15px"}
                                      onClick={() =>
                                        handleRemovePdf(
                                          link,
                                          indexlinkIndex,
                                          index,
                                          "analytics_data",
                                          category?.id_sport
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                </>
                              )
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col key={`verification${index}`} span={20}>
                        <div className="text-base font-medium text-black flex items-center gap-4 mb-2 mt-3">
                          Stat Verification MAXPREPS Link
                          <div
                            type="primary"
                            className="mr-4 py-0 flex items-center justify-center bg-blue-600 text-white w-6 h-6 rounded"
                            onClick={() => handleAddLink(index, "statlink")}
                          >
                            <PlusOutlined className="" />
                          </div>
                        </div>
                      </Col>
                      {category.maxpreps_tab.map((link, linkIndex) => (
                        <Col key={linkIndex} span={20}>
                          <div className="mr-4 mb-6 flex items-center gap-3">
                            <Input size="large" value={link.value} onChange={(e) =>
                            handleInputLinkChange(
                              index,
                              linkIndex,
                              "statlink",
                              e.target.value
                            )
                          }/>

                            <CloseCircleOutlined
                              onClick={() =>
                                handleRemoveLink(index, linkIndex, "statlink")
                              }
                              style={{
                                fontSize: "24px",
                                color: "red",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </Col>
                      ))}
                  </Row>
                ),
              }))}
            />
          </div>
        </div>
        <Row>
          <Col span={24}>
            <div className="flex justify-end my-5">
              <Button
                type="primary"
                size={14}
                className="mx-1.5 cursor-pointer flex items-center justify-between"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};
export default SportsDetails;
