import { useState, useEffect } from "react";
import { Card, Col, Form, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import AthleteService from "../../../service/AthleteService";
import moment from 'moment';
import { decodeId } from '../../../utils/encoding';
import PaginationComponent from "../../../Common/Pagination";

const AthleteSer = new AthleteService();

const AppliedApplications = () => {
  const { id } = useParams();
  const athleteId = decodeId(id);
  const navigate = useNavigate();
  const [applicationDetails, setApplicationDetails] = useState([]);
  const [expandedStates, setExpandedStates] = useState([]);
  const [filters, setFilters] = useState({ page: 1, limit:10 });

  const handleReadMore = (index) => {
    setExpandedStates(prevState => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  useEffect(() => {
    if (!athleteId) {
      navigate('/manage-athlete');
      return;
    }
    const filterData = async () => {
      try{
        const response = await AthleteSer.getApplication(athleteId,filters);
        setApplicationDetails(response?.data);
        setExpandedStates(new Array(response?.data?.rows?.length).fill(false));
      }catch(error){
        let msg = AthleteSer.errorMessage(error);
        //showAlert(msg, "error");
      }
    };
    
    filterData();
  }, [id, filters]);
  
  const handlePageChange = (page, size) => {
    setFilters({ ...filters, page, limit: size });
  };

  return (
    <>
      <div className="flex justify-between items-center mb-3">
        <div className="font-semibold text-base mb-[10px]">
          Applied Applications
        </div>
      </div>
      {applicationDetails?.rows?.length > 0 ? (
        applicationDetails?.rows?.map((val, index) => (
        <Card className="mb-10" key={index}>
          <div>
            <Form
              layout="vertical"
              style={{
                width: "100%",
              }}
              initialValues={{
                remember: true,
              }}
              autoComplete="off"
            >
              <div className="max-w-5xl">
                <Row gutter={[40, 16]}>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={24}
                  >
                    <div className="text-base font-medium text-black w-[250px]">
                      Coach Name :
                    </div>
                    <div className="text-base">{val?.coache?.first_name} {val?.coache?.last_name}</div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={24}
                  >
                    <div className="text-base font-medium text-black w-[250px]">
                      College Details :
                    </div>
                    <div className="text-base">
                      {val?.college?.name}
                    </div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={24}
                  >
                    <div className="text-base font-medium text-black w-[250px]">
                      Mode :
                    </div>
                    <div className="text-base">{(val?.type)==='email'?'Email':((val?.type)==='fb'?'Facebook':((val?.type)==='insta'?'Instagram':'Twitter'))}</div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={24}
                  >
                    <div className="text-base font-medium text-black w-[250px]">
                      Message Sent :
                    </div>
                    <div className="text-base w-[70%]">
                      {val?.message.length > 100 ? (
                        <>
                          {expandedStates[index] ? (
                            <>
                              <span>{val?.message}</span>
                              <span
                                onClick={() => handleReadMore(index)}
                                className="text-blue-500 cursor-pointer"
                              >
                                Show less
                              </span>
                            </>
                          ) : (
                            <>
                              {val?.message.slice(0, 100)}...
                              <span
                                onClick={() => handleReadMore(index)}
                                className="text-blue-500 cursor-pointer"
                              >
                                Read more
                              </span>
                            </>
                          )}
                        </>
                      ) : (
                        <span>{(val?.message)?(val?.message):'-'}</span>
                      )}
                    </div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={24}
                  >
                    <div className="text-base font-medium text-black w-[250px]">
                      Applied date & time :
                    </div>
                    <div className="text-base">{moment(val?.created_at).format('MM/DD/YYYY')} - {moment(val?.created_at).format('h:mm a')}</div>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </Card>
      ))
      ) : (
        <div className="max-w-5xl">
            <Row gutter={[40, 16]}>
              <Col style={{
                width: "100%",
                textAlign:"center",
              }} className="gutter-row flex items-start justify-start">
                No records found
              </Col>
            </Row>
        </div>
      )}
      {applicationDetails?.count > 10 && (
        <div className="flex justify-between items-center">
          <div className="font-semibold text-base">
            Total count : <span>{applicationDetails?.count}</span>
          </div>
          <PaginationComponent
              currentPage={filters.page}
              total={applicationDetails?.count}
              pageSize={filters.limit}
              onPageChange={handlePageChange}
            />
        </div>
      )}
    </>
  );
};

export default AppliedApplications;
