import {
  Button,
  Card,
  Divider,
  Typography,
  Col,
  Form,
  Input,
  Row,
  Select,
} from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { useState, useEffect } from "react";
import CoachService from "../../service/CoachService";
import CollegeService from "../../service/CollegeService";
import { useAlert } from "../../contexts/AlertProvider";
import { validatePhoneNumber, validateUrl } from "../../utils/validation";
const { Option } = Select;
const CoachSer = new CoachService();
const CollegeSer = new CollegeService();

const CreateCoach = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [phoneNumberInput, setPhoneNumberInput] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [form] = Form.useForm();
  const [collegeList, setCollegeList] = useState({});
  const { showAlert } = useAlert();
  const [sportsCategory, setSportsCategory] = useState();

  const getCategoryListByCollegeId = async (collegeId) => {
    if (!collegeId) return;
    const category = await CollegeSer.getCategoryListByCollegeId(collegeId);
    const categoryData = category.data.map((item) => ({
      value: item.id,
      label: `${item.name} (${item.gender === "male" ? "M" : "F"})`,
    }));
    setSportsCategory((prev)=>categoryData);
  };

  useEffect(() => {
    if (id) {
      setIsEditing(true);
      const fetchProfileData = async () => {
        const response = await CoachSer.getCoachDetails(id);
        const data = response?.data;

        if (data) {
          const socialLinks = data.social_media_link.reduce((acc, link) => {
            acc[`${link.name}_link`] = link.link;
            return acc;
          }, {});

          setPhoneNumberInput(data.contact_number);
          form.setFieldsValue({ ...data, ...socialLinks });
          setIsDisabled(false);

          // if (data?.college_id) {
          //   const category = await CollegeSer.getCategoryListByCollegeId(data?.college_id);
          //   const categoryData = category.data.map((item) => ({
          //     value: item.id,
          //     label: `${item.name} (${item.gender === "male" ? "M" : "F"})`,
          //   }));
          //   setSportsCategory(categoryData);
          // }
          getCategoryListByCollegeId(data?.college_id);
        }
      };
      fetchProfileData();
    } else {
      setIsDisabled(false);
    }
  }, [id, form]);

  useEffect(() => {
    const fetchCollegeData = async () => {
      try {
        const response = await CoachSer.getCollegeList();
        if (response?.data) {
          const collegeArray = response.data.map((clg) => ({
            value: clg.id,
            label: clg.name,
          }));
          setCollegeList(collegeArray);
        }
        // const category = await CollegeSer.getCategoryListByCollegeId();
        // const data = category.data.map((item) => ({
        //   value: item.id,
        //   label: `${item.name} (${item.gender === "male" ? "M" : "F"})`,
        // }));
       // setSportsCategory(data);
      } catch (error) {
        console.error("Failed to fetch college data:", error);
      }
    };
    fetchCollegeData();
  }, []);

  const handleSubmit = async (values) => {
    setIsDisabled(true);
    const formattedData = {
      ...values,
      contact_number: phoneNumberInput,
      social_media_link: [
        { name: "fb", link: values.fb_link },
        { name: "insta", link: values.insta_link },
        { name: "twitter", link: values.twitter_link },
      ].filter((link) => link.link), // Filter out empty links
    };

    try {
      delete formattedData.fb_link;
      delete formattedData.insta_link;
      delete formattedData.twitter_link;
      if (isEditing) {
        formattedData.id = parseInt(id);
        let resonse = await CoachSer.updateCoach(formattedData);
        showAlert(resonse?.message, "success");
      } else {
        let resonse = await CoachSer.addCoach(formattedData);
        showAlert(resonse?.message, "success");
      }
      navigate(-1); // Navigate back after successful submission
    } catch (error) {
      let msg = CoachSer.errorMessage(error);
      showAlert(msg, "error");
      setIsDisabled(false);
      //setIsDisabled(false);
    }
  };

  const onInputChange = (value, field) => {
    const regex = /^[a-zA-Z\s]*$/;
    if (value && !regex.test(value)) {
      form.setFieldsValue({
        [field]: value.slice(0, -1),
      });
    }
  };

  const gotoBack = () => {
    navigate("/manage-coach");
  };
  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  const handleCollegeChange = async (value) => {
    getCategoryListByCollegeId(value);
    form.setFieldsValue({ sports_category: null });
  };

  return (
    <>
      <Card>
        <div className="flex justify-between items-center mb-4 gap-3">
          <Typography className="text-xl font-semibold" align={"left"}>
            <LeftOutlined
              className="text=lg text-black mr-2 cursor-pointer"
              onClick={() => navigate(-1)}
            />
            {isEditing ? "Edit Coach" : "Add Coach"}
          </Typography>
        </div>
        <Divider />
        <Card>
          <div>
            <div className="font-semibold text-base mb-[10px]">
              Personal Information
            </div>
            <Form
              form={form}
              layout="vertical"
              style={{ width: "100%" }}
              initialValues={{ remember: true }}
              onFinish={handleSubmit}
              autoComplete="off"
            >
              <Row>
                <Col span={10}>
                  <div className="mr-4">
                    <Form.Item
                      label="First Name"
                      name="first_name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your First Name.",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        maxLength={25}
                        onChange={(e) =>
                          onInputChange(e.target.value, "first_name")
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={10}>
                  <div className="mr-4">
                    <Form.Item
                      label="Last Name"
                      name="last_name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Last Name.",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        maxLength={25}
                        onChange={(e) =>
                          onInputChange(e.target.value, "last_name")
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={10}>
                  <div className="mr-4">
                    <Form.Item
                      label="Phone no"
                      name="contact_number"
                      // rules={[
                      //   { required: true, message: 'Please enter your Phone no.' },
                      //   { validator: validatePhoneNumber },
                      // ]}
                    >
                      <Input
                        size="large"
                        maxLength={10}
                        value={phoneNumberInput} 
                        onKeyPress={handleKeyPress}
                        onChange={(e) => setPhoneNumberInput(e.target.value)} 
                        addonBefore={
                          <div className="pointer-events-none flex items-center">
                            <span className="text-small flex items-center gap-3 mr-2">
                              +1
                            </span>
                          </div>
                        }
                      />

                      {/* <PhoneInput
                        className="border border-[#d9d9d9] rounded-lg px-[11px] h-[40px]"
                        value={phoneNumberInput}
                        maxLength={16}
                        international
                        defaultCountry="US"
                        countryCallingCodeEditable={false}
                        disableCountryCode={true}
                        onChange={(value) => {
                          try {
                            if (value) {
                              const phoneNumber = parsePhoneNumber(value);
                              setPhoneNumberInput(
                                `${phoneNumber.countryCallingCode} ${phoneNumber.nationalNumber}`
                              );
                            } else {
                              setPhoneNumberInput(value);
                            }
                          } catch (error) {
                            console.error("Invalid phone number format", error);
                          }
                        }}
                      /> */}
                    </Form.Item>
                  </div>
                </Col>
                <Col span={10}>
                  <div className="mr-4">
                    <Form.Item
                      label="Email ID"
                      name="email_address"
                      rules={[
                        {
                          required: true,
                          type: "email",
                          message: "Please enter a valid email address.",
                        },
                      ]}
                    >
                      <Input size="large" maxLength={100} />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={10}>
                  <div className="mr-4">
                    <Form.Item
                      label="Associate college"
                      name="college_id"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Associate college.",
                        },
                      ]}
                    >
                      {/* <Select size="large" options={collegeList} /> */}
                      <Select
                        size="large"
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={handleCollegeChange}
                        placeholder="Select a college"
                        style={{ width: "100%" }}
                      >
                        {Array.isArray(collegeList) &&
                          collegeList.map((college) => (
                            <Option key={college.value} value={college.value}>
                              {college.label}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>

                <Col span={10}>
                  <div className="mr-4">
                    <Form.Item
                      label="Sports offer"
                      name="sports_category"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Sport.",
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        size="large"
                        options={sportsCategory}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <div className="font-semibold text-base mb-[10px]">
                Social Media Links
              </div>
              <Row>
                <Col span={10}>
                  <div className="mr-4">
                    <Form.Item
                      label="Facebook"
                      name="fb_link"
                      rules={[{ validator: validateUrl }]}
                    >
                      <Input size="large" />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={10}>
                  <div className="mr-4">
                    <Form.Item
                      label="Instagram"
                      name="insta_link"
                      rules={[{ validator: validateUrl }]}
                    >
                      <Input size="large" />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={10}>
                  <div className="mr-4">
                    <Form.Item
                      label="Twitter"
                      name="twitter_link"
                      rules={[{ validator: validateUrl }]}
                    >
                      <Input size="large" />
                    </Form.Item>
                  </div>
                </Col>
                {/* <Col span={10}>
                  <div className="mr-4">
                    <Form.Item
                      label="LinkedIn"
                      name="linkedIn_link"
                      rules={[{ required: true, message: "Please enter your LinkedIn URL." }]}
                    >
                      <Input size="large" />
                    </Form.Item>
                  </div> 
                </Col>*/}
              </Row>
              <div className="font-semibold text-base mb-[10px]">
                Current Position
              </div>
              <Row>
                <Col span={10}>
                  <div className="mr-4">
                    <Form.Item
                      label="Title"
                      name="current_position"
                      rules={[
                        { required: true, message: "Please enter your Title." },
                      ]}
                    >
                      <Input size="large" maxLength={50} />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <div className="flex justify-end my-5">
                {isEditing && (
                  <Button
                    type="primary"
                    htmlType="button"
                    size={14}
                    className="mx-1.5 cursor-pointer flex items-center justify-between"
                    onClick={gotoBack}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  type="primary"
                  htmlType="submit"
                  size={14}
                  disabled={isDisabled}
                  className="mx-1.5 cursor-pointer flex items-center justify-between"
                >
                  {isEditing ? "Update" : "Add"}
                </Button>
              </div>
            </Form>
          </div>
        </Card>
      </Card>
    </>
  );
};

export default CreateCoach;
