import { useState, useEffect } from "react";
import { Tooltip, Card, Select, Button } from "antd";
import { EditOutlined, DeleteOutlined,EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Input } from "antd";
import SortingArrow from "../../Common/SortingArrow";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import PaginationComponent from "../../Common/Pagination";
import AthleteService from "../../service/AthleteService";
import PlanService from "../../service/PlanService";
import { useAlert } from "../../contexts/AlertProvider";
import { useLoader } from "../../contexts/LoadingProvider";
import { encodeId } from '../../utils/encoding';


const genderUnit = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Non-binary", label: "Non-binary" },
  { value: "Other", label: "Other" },
];

const statusUnit = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "Inactive" },
  { value: "deactivated", label: "Deactivated" }

];

const selectPlan1 = [
  {
    value: 1,
    label: "Free",
  },
  {
    value: 1,
    label: "Eco Plan",
  },
  {
    value: 2,
    label: "Premium Plan",
  },
];
const AthleteSer = new AthleteService();
const PlanSer = new PlanService();
const ManageAthlete = () => {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { setLoading } = useLoader();
  const [athleteListing, setAthleteListing] = useState([]);
  const [sportsCategory, setSportsCategory] = useState([]);
  const { Search } = Input;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteId, setIsDeleteId] = useState();
  const [searchData, setSearchData] = useState("");
  const [genderFilter, setGenderFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [planFilter, setPlanFilter] = useState("");
  const [filters, setFilters] = useState({ page: 1, search: '', plan_id: '', gender:'', status: '', sport_cat:'', limit:10,sort:'',order:'' });
  const [selectPlan, setSelectPlan] = useState([]);

  useEffect(() => {
    const filterAthletes = async () => {
      setLoading(true);
      const response = await AthleteSer.getAthleteList(filters);
      setAthleteListing(response?.data);
      const category = await AthleteSer.getCategoryList(filters);
      const data = category.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      const planList = await PlanSer.getPlanList({sort:'id',order:'asc'});
      const dataPlan = planList?.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setSelectPlan(dataPlan)
      setSportsCategory(data);
      setLoading(false);
    };
    
    filterAthletes();
  }, [filters]);
  
  const handleSearchChange = (e) => {
    const value = e.target.value;
    const trimmedValue = value.replace(/^\s+/, '');
    setSearchData(trimmedValue);
    if (trimmedValue.length > 2) {
      setFilters({ ...filters, page:1, search: trimmedValue });
    } else if (value.length === 0) {
      setFilters({ ...filters, page:1, search: '' });
    }
  };
  
  const handleDeleteOk =async () => {
    try {
    const response = await AthleteSer.updateAthleteStatus(isDeleteId, 'deleted');
    setIsDeleteModalOpen(false);
    setAthleteListing((prev) => ({
      ...prev,
      rows: prev.rows.filter((athlete) => athlete.id !== isDeleteId),
      count: prev.count - 1,
    }));
    showAlert(response?.message, "success");
    } catch (error) {
      let msg = AthleteSer.errorMessage(error);
      showAlert(msg, "error");
    }
  };
  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };
  const handlePageChange = (page, size) => {
    setFilters({ ...filters, page, limit: size });
  };
  const handleDeletePopupClick = (id) => {
    setIsDeleteModalOpen(true)
    setIsDeleteId(id);
  };
  const clearFilter = ()=>{
    setSearchData("");
    setGenderFilter("");
    setStatusFilter("");
    setCategoryFilter("");
    setPlanFilter("");
    setFilters({ ...filters, page: 1, search: '', plan_id: '', gender:'', status: '', sport_cat:'', plan_id:'', limit:10,sort:'',order:'' });
  }
  const handleGenderChange = (value) => {
    let jsonString = "";
    if(value?.length !== 0)
     jsonString = JSON.stringify(value);
    
    setGenderFilter(jsonString);
    setFilters({ ...filters, page:1, gender: jsonString });
  };
  const handleStatusChange = (value) => {
    let jsonString = "";
    if(value?.length !== 0)
     jsonString = JSON.stringify(value);
    
    setStatusFilter(jsonString);
    setFilters({ ...filters, page:1, status: jsonString });
  };
  const handleCategoryChange = (value) => {
    let jsonString = "";
    if(value?.length !== 0)
     jsonString = JSON.stringify(value);
    
    setCategoryFilter(jsonString);
    setFilters({ ...filters, page:1, sport_cat: jsonString });
  };
  const handlePlanChange = (value) => {
    let jsonString = "";
    if(value?.length !== 0)
     jsonString = JSON.stringify(value);
    
    setPlanFilter(jsonString);
    setFilters({ ...filters, page:1, plan_id: jsonString });
  };
  
  const handleSort = (field, key) => {
    setFilters({ ...filters, page:1, sort:field, order: key });
  };

  const filterOption = (input, option) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };
  return (
    <>
      <Card>
      <div className="flex justify-between	mb-4 gap-5">
        <Search
            placeholder="Search Athlete by Name or Email ID..."
            value={searchData}
            style={{
              width: 300,
            }}
            className="cursor-pointer"
            onChange={handleSearchChange}
          />
           <Select
              style={{
                width: 200,
              }}
              maxTagCount="responsive"
            mode="multiple"
            allowClear
              placeholder="Filter By Gender"
              options={genderUnit}
              className="cursor-pointer"
              value={genderFilter ? JSON.parse(genderFilter) : []}
              onChange={handleGenderChange}
              showSearch={false}
              filterOption={false}
            />
            <Select
              style={{
                width: 200,
              }}
              maxTagCount="responsive"
            mode="multiple"
            allowClear
              placeholder="Status"
              options={statusUnit}
              value={statusFilter ? JSON.parse(statusFilter) : []}
              onChange={handleStatusChange}
              className="cursor-pointer"
              showSearch={false}
              filterOption={false}
            />
            <Select
              style={{
                width: 200,
              }}
              maxTagCount="responsive"
            mode="multiple"
            allowClear
              placeholder="Sports"
              options={sportsCategory}
              filterOption={filterOption}
              className="cursor-pointer"
              value={categoryFilter ? JSON.parse(categoryFilter) : []}
              onChange={handleCategoryChange}
            />
            <Select
              style={{
                width: 150,
              }}
              maxTagCount="responsive"
              mode="multiple"
              allowClear
              placeholder="Plan"
              options={selectPlan}
              className="cursor-pointer"
              value={planFilter ? JSON.parse(planFilter) : []}
              onChange={handlePlanChange}
              showSearch={false}
              filterOption={false}
            />
            <Button
              type="link"
              size={14}
              className="cursor-pointer"
              onClick={clearFilter}
            >
              Clear Filter
            </Button>
          </div>
        <div className="relative overflow-x-auto">
          <table className="w-full  text-left	 text-sm  text-gray-500">
            <thead className=" text-gray-700 capitalize text-xs	 bg-gray-50">
              <tr>
                <th scope="col" className="px-3 py-3 whitespace-nowrap">
                  Athlete ID <SortingArrow  onSort={handleSort}  field="id" />
                </th>
                <th scope="col" className="px-3 py-3">
                  Full Name <SortingArrow onSort={handleSort}  field="first_name"/>
                </th>
                <th scope="col" className="px-3 py-3">
                  Email ID <SortingArrow onSort={handleSort} field="email"/>
                </th>
                <th scope="col" className="px-3 py-3">
                   Sports {/*<SortingArrow onSort={handleSort}/> */}
                </th>
                <th scope="col" className="px-3 py-3">
                  Gender <SortingArrow onSort={handleSort} field="gender"/>
                </th>
                <th scope="col" className="px-3 py-3">
                   Subscription {/*<SortingArrow onSort={handleSort}/> */}
                </th>
                <th scope="col" className="px-3 py-3 text-center">
                  Status 
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-center whitespace-nowrap vertical-top"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
            {athleteListing?.rows?.length > 0 ? (
              athleteListing.rows.map((athlete, index) => (
                <tr key={index} className="bg-white border-b">
                  <th
                    scope="row"
                    className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top cursor-pointer"
                  onClick={() => navigate(`/view-athlete/${encodeId(athlete.id)}`)}
                  >
                    {athlete.id}
                  </th>
                  <td className="px-3 py-4 vertical-top">{athlete.first_name} {athlete.last_name}</td>
                  <td className="px-3 py-4 vertical-top">{athlete.email}</td>
                  <td className="px-3 py-4 vertical-top">{athlete?.sport_cat?.map(cat => cat.name).join(", ")}</td>
                  <td className="px-3 py-4 vertical-top">{athlete.gender}</td>
                  <td className="px-3 py-4 vertical-top">{(athlete?.subscription_plan?.name)?(athlete?.subscription_plan?.name):'-'}</td>
                  <td className="px-3 py-4 vertical-top">
                    <p
                    className={`text-xs font-medium bg-[#01940033] rounded-lg text-center p-2 ${
                      athlete?.status === 'pending' ? 'text-[#faad14] bg-[#ffd70b40]' :
                      (athlete?.status === 'rejected' || athlete?.status === 'inactive' || athlete?.status === 'deactivated' ) ? 'text-[#DC362E] bg-[#DC362E40]' : 'text-[#019400] bg-[#01940033]'
                    }`}>
                     {athlete.status}
                    </p>
                  </td>
                  <td className="px-3 py-4 text-center whitespace-nowrap vertical-top">
                    <Tooltip title="View Athlete">
                          <EyeOutlined
                            className="mx-1.5 cursor-pointer"
                            onClick={() => navigate(`/view-athlete/${encodeId(athlete.id)}`)}
                          />
                        </Tooltip>
                    <Tooltip title="Edit">
                      <EditOutlined
                        onClick={() => navigate(`/edit-athlete/${encodeId(athlete.id)}`)}
                        className="mx-1.5 cursor-pointer"
                      />
                    </Tooltip>
                    <Tooltip title="Delete">
                      <DeleteOutlined
                        className="mx-1.5 cursor-pointer"
                        onClick={() => handleDeletePopupClick(athlete.id)}
                      />
                    </Tooltip>
                  </td>
                </tr>
                ))
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center px-3 py-4 text-gray-500">
                      No records found
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between items-center">
          <div className="font-semibold text-base">
            Total count : <span>{athleteListing?.count}</span>
          </div>
          {athleteListing?.count > 10 && (
            <PaginationComponent
              currentPage={filters.page}
              total={athleteListing?.count}
              pageSize={filters.limit}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </Card>
      {/* delete modal */}
      <ConfirmationModal
        ConfirmationHeading="Delete"
        ConfirmationParagraph="Are you sure you want to delete this Athlete?"
        isOpen={isDeleteModalOpen}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
    </>
  );
};
export default ManageAthlete;