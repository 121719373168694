// This page is for both add and edit
import { LeftOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Typography,
  Slider,
} from "antd";
import { useState, useEffect, useRef } from "react";
import CollegeService from "../../service/CollegeService";
import { useAlert } from "../../contexts/AlertProvider";
import ImageCropper from "../../Modal/ImageCropper";
import { Config } from "../../constants/Config";
import { validateUrl } from "../../utils/validation";
import PlacesSearch from "../../contexts/PlaceSearch";
import { useLoader } from "../../contexts/LoadingProvider";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";

const CollegeSer = new CollegeService();
const divisionOption = [
  { value: "1", label: "JC" },
  { value: "2", label: "NAIA" },
  { value: "3", label: "NCAA DIII" },
];
const blogCategory = [
  { value: "1", label: "Option 1" },
  { value: "2", label: "Option 2" },
];
const collegeType = [
  { value: "public", label: "Public" },
  { value: "private", label: "Private" },
];
const SATACOption = [
  { value: "1", label: "Yes" },
  { value: "2", label: "No" },
];
const CreateCollege = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [forms, setForms] = useState([]);
  const { setLoading } = useLoader();
  const [phoneNumberInput, setPhoneNumberInput] = useState();
  const [sportsCategory, setSportsCategory] = useState();
  const [sportsDivision, setSportsDivision] = useState();
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const inputRef = useRef(null);
  const [form] = Form.useForm();
  const { showAlert } = useAlert();
  const [isEditing, setIsEditing] = useState(false);
  const [actMaxValue, setActMaxValue] = useState(75);
  const [satMaxValue, setSatMaxValue] = useState(75);
  const [isUploadProfilePhotoOpen, setIsUploadProfilePhotoOpen] = useState();
  const handleUploadProfilePhotoOpen = () => setIsUploadProfilePhotoOpen(true);
  const handleUploadProfilePhotoClose = () =>
    setIsUploadProfilePhotoOpen(false);
  const [croppedImages, setCroppedImages] = useState([]);
  const [databaseImages, setDatabaseImages] = useState([]);
  const [collegeId, setcollegeId] = useState();
  const [addressError, setAddressError] = useState("");
  const [details, setDetails] = useState({
    zipcode: "",
    lat: "",
    lng: "",
    city: "",
    state: "",
    country: "",
  });
  const [imageMaxLimit, setImageMaxLimit] = useState(0);

  const handleCroppedImage = (imageData) => {
    setCroppedImages((prevImages) => [...prevImages, imageData]);
    setImageMaxLimit((prevLimit) => prevLimit + 1);
  };

  useEffect(() => {
    const filterAthletes = async () => {
      const category = await CollegeSer.getCategoryList();
      const division = await CollegeSer.getDivisionList();
      const data = category.data.map((item) => ({
        value: item.id,
        label: `${item.name} (${item.gender === "male" ? "M" : "F"})`,
      }));
      setSportsCategory(data);
      const data1 = division.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setSportsDivision(data1);
    };
    filterAthletes();
  }, []);

  useEffect(() => {
    if (id) {
      setIsEditing(true);
      const fetchProfileData = async () => {
        const response = await CollegeSer.getCollegeDetails(id);
        const data = response?.data;

        if (data) {
          setcollegeId(data.id);
          setAddress(data.address);
          const sport_category_ids = data.sport_category_id.map(
            (category) => category.id
          );
          // const division_ids = data.division_id.map(
          //   (division) => division.id
          // );
          const division_ids = data?.division_id?.[0]?.id;
          setDatabaseImages(data.images);
          setImageMaxLimit(data?.images?.length);
          form.setFieldsValue({
            ...data,
            sport_category_id: sport_category_ids,
            division_id: division_ids,
          });
          if (data.college_contacts) {
            const contactForms = data.college_contacts.map(
              (contact, index) => ({
                id: index + 1,
              })
            );
            setForms(contactForms);

            // Set form values for each contact
            data.college_contacts.forEach((contact, index) => {
              form.setFieldsValue({
                [`first_name_${index + 1}`]: contact.first_name,
                [`last_name_${index + 1}`]: contact.last_name,
                [`contact_number_${index + 1}`]: contact.contact_number,
                [`email_id_${index + 1}`]: contact.email_id,
              });
            });
          }
          //setIsDisabled(false);
        }
      };
      fetchProfileData();
    } else {
      //setIsDisabled(false);
    }
  }, [id, form]);

  const handleChange = (e) => {
    console.log(e.target.value, "e.target.value");
    setAddress(e.target.value);
  };

  const addForm = () => {
    setForms([...forms, { id: forms.length + 1 }]);
  };
  const removeForm = (id) => {
    setForms(forms.filter((form) => form.id !== id));
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleSubmit = async (values) => {
    // setIsDisabled(true);
    try {
      const contactPersons = forms.map((form) => ({
        first_name: values[`first_name_${form.id}`],
        last_name: values[`last_name_${form.id}`],
        contact_number: values[`contact_number_${form.id}`],
        email_id: values[`email_id_${form.id}`],
      }));
      let allimages = croppedImages.concat(databaseImages);
      const formattedData = {
        ...values,
        latitude: details.lat,
        longitude: details.lng,
        contact_person: contactPersons,
        address: address,
        images: allimages,
        division_id: Array.isArray(values?.division_id)
          ? values?.division_id
          : [values?.division_id],
      };

      // const payload = {
      //   name: values.name,
      //   contact_person: contactPersons,
      // };
      // payload.latitude= "42.569878";
      // payload.longitude="75.569878";
      console.log(formattedData, "formattedData");
      //return;
      if (!address) {
        setAddressError("Please select address.");
        return;
      }
      if (isEditing) {
        formattedData.id = parseInt(collegeId);
        let response = await CollegeSer.updateCollege(formattedData);
        showAlert(response?.message, "success");
      } else {
        let response = await CollegeSer.addCollege(formattedData);
        showAlert(response?.message, "success");
      }
      navigate(-1);
    } catch (error) {
      let msg = CollegeSer.errorMessage(error);
      showAlert(msg, "error");
      // setIsDisabled(false);
    }
  };

  const handleSliderChange = (newMaxValue, scoreType) => {
    if (scoreType === "act_score") {
      setActMaxValue(newMaxValue);
      form.setFieldsValue({ act_score: newMaxValue });
    } else if (scoreType === "sat_score") {
      setSatMaxValue(newMaxValue);
      form.setFieldsValue({ sat_score: newMaxValue });
    }
  };

  const onPlaceSelect = (placeDetails) => {
    setDetails(placeDetails);
    setAddress(placeDetails?.address);
    setAddressError("");
  };

  // Update form values whenever details changes
  useEffect(() => {
    form.setFieldsValue({
      country: details?.country,
      zipcode: details?.zip,
      city: details?.city,
      state: details?.state,
    });
  }, [details, form]);
  const removeImageDB = (imageToRemove, index) => {
    setDatabaseImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setImageMaxLimit((prevLimit) => prevLimit - 1);
  };
  const removeImageLocal = (imageToRemove, index) => {
    setCroppedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setImageMaxLimit((prevLimit) => prevLimit - 1);
  };

  const onInputChange = (value, field) => {
    const regex = /^\d*\.?\d{0,2}$/;
    if (value && !regex.test(value)) {
      form.setFieldsValue({
        [field]: value.slice(0, -1),
      });
    }
  };

  const handleInputChange = (value) => {
    setPhoneNumberInput(value);
  };

  const getFormattedNumber = () => {
    if (phoneNumberInput) {
      try {
        const phoneNumber = parsePhoneNumber(phoneNumberInput);
        return `${phoneNumber.countryCallingCode} ${phoneNumber.nationalNumber}`;
      } catch (error) {
        console.error("Invalid phone number", error);
      }
    }
    return "";
  };
  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };
  return (
    <>
      <Card>
        <div className="flex justify-between items-center mb-4 gap-3">
          <div>
            <Typography className="text-xl font-semibold" align={"left"}>
              <LeftOutlined
                className="text=lg text-black mr-2 cursor-pointer"
                onClick={() => navigate(-1)}
              />
              Add/Edit College Details
            </Typography>
          </div>
        </div>
        <Divider />
        <Form
          form={form}
          layout="vertical"
          style={{
            //   maxWidth: 600,
            width: "100%",
          }}
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={handleSubmit}
        >
          <div>
            <Row>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Name of College"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Name of College.",
                      },
                    ]}
                  >
                    <Input size="large" maxLength={50} />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Add division "
                    name="division_id"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your division.",
                      },
                    ]}
                  >
                    <Select size="large" options={sportsDivision} />
                  </Form.Item>
                </div>
              </Col>

              <Col span={20}>
                <div className="mr-4">
                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Description.",
                      },
                    ]}
                  >
                    <Input.TextArea showCount maxLength={200} size="large" />
                  </Form.Item>
                </div>
              </Col>
              {/* <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Gender applicable "
                    name="Gender applicable "
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Gender applicable.",
                      },
                    ]}
                  >
                    <Select mode="multiple" size="large" options={genderOption} />
                  </Form.Item>
                </div>
              </Col> */}
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Sports offer"
                    name="sport_category_id"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Sport.",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      size="large"
                      options={sportsCategory}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Website URL"
                    name="website_url"
                    rules={[{ validator: validateUrl }]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={20}>
                <label>
                  Image<span className="text-sm text-red-500">*</span>
                </label>
                <div className="flex gap-3 items-start mb-5 mt-2">
                  {imageMaxLimit < 5 && (
                    <div className="relative cursor-pointer">
                      <div
                        onClick={handleUploadProfilePhotoOpen}
                        className="border-dashed border border-[#d9d9d9] w-[100px] h-[100px] rounded-lg flex flex-col justify-center items-center"
                      >
                        <img
                          src={`${Config.appUrl}images/upload.svg`}
                          alt="college-banner"
                          className="h-[24px] w-[24px] object-cover rounded overflow-hidden cursor-pointer"
                        />
                        <h6>Upload</h6>
                      </div>
                    </div>
                  )}
                  <div className="flex flex-wrap gap-3">
                    {databaseImages.map((images, index) => (
                      <div key={index} className="relative">
                        <img
                          src={images}
                          className="w-[100px] h-[100px] rounded-lg"
                          alt=""
                        />
                        <div className="absolute right-[-3px] top-[-3px] z-10 cursor-pointer">
                          <img
                            src="/images/cancel.svg"
                            alt="remove"
                            width={"20px"}
                            height={"20px"}
                            onClick={() => removeImageDB(images, index)}
                          />
                        </div>
                      </div>
                    ))}
                    {croppedImages.map((images, index) => (
                      <div key={index} className="relative">
                        <img
                          src={images}
                          className="w-[100px] h-[100px] rounded-lg"
                          alt=""
                        />
                        <div className="absolute right-[-3px] top-[-3px] z-10 cursor-pointer">
                          <img
                            src="/images/cancel.svg"
                            alt="remove"
                            width={"20px"}
                            height={"20px"}
                            onClick={() => removeImageLocal(images, index)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col span={10}>
                <div className="text-base font-medium text-black">
                  Address details :
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={20}>
                <div className="mr-4 mb-6">
                  <label>
                    Address<span style={{ color: "#ED5556" }}>*</span>
                  </label>
                  <PlacesSearch
                    initialAddress={address}
                    onPlaceSelect={onPlaceSelect}
                  />
                  {/* <Form.Item
                    label="Address"
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Address .",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      ref={inputRef}
                      value={address}
                      onChange={handleChange}
                    />
                  </Form.Item> */}
                  <div
                    class="ant-form-item-explain-error"
                    style={{ color: "#ff4d4f" }}
                  >
                    {addressError}
                  </div>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Country"
                    name="country"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Country.",
                      },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="State/Province"
                    name="state"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your State/Province.",
                      },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="City"
                    name="city"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your City.",
                      },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Zipcode"
                    name="zipcode"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Zipcode .",
                      },
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item label="Contact Number" name="contact_number"
                  rules={[
                    {
                      min: 10,
                      message: "Contact number must be at least 10 digits.",
                    },
                  ]}>
                    <Input
                      size="large"
                      maxLength={15}
                      value={phoneNumberInput}
                      onKeyPress={handleKeyPress}
                      onChange={(e) => setPhoneNumberInput(e.target.value)}
                      addonBefore={
                        <div className="pointer-events-none flex items-center">
                          <span className="text-small flex items-center gap-3 mr-2">
                            +1
                          </span>
                        </div>
                      }
                    />
                    {/* <div>
                      <PhoneInput
                        className="border border-[#d9d9d9] rounded-lg px-[11px] h-[40px]"
                        international
                        defaultCountry="US"
                        value={phoneNumberInput}
                        onChange={handleInputChange}
                      />
                      <p className="hidden">Formatted: {getFormattedNumber()}</p>
                    </div> */}
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Email ID"
                    name="email_address"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.resolve();
                          }
                          const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                          if (!regex.test(value)) {
                            return Promise.reject(
                              new Error("Please enter a valid Email ID.")
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      maxLength={100}
                      onKeyPress={(event) => {
                        const regex = /^[a-zA-Z0-9@.]+$/;
                        if (!regex.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col span={10}>
                <div className="text-base font-medium text-black">
                  Contact Person Details :{" "}
                </div>
              </Col>
              <Col span={10} className="text-right">
                <Button type="primary" className="mr-4" onClick={addForm}>
                  Add Contact
                </Button>
              </Col>
            </Row>
            {forms.map((form, index) => (
              <div key={form.id}>
                {index > 0 && <Divider />}
                <Row>
                  <Col span={10}>
                    <div className="text-base font-medium text-black">
                      Contact Details {form.id}{" "}
                    </div>
                  </Col>
                  <Col span={10} className="text-right pt-5">
                    <CloseCircleOutlined
                      onClick={() => removeForm(form.id)}
                      style={{
                        fontSize: "24px",
                        color: "red",
                        cursor: "pointer",
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={10}>
                    <div className="mr-4">
                      <Form.Item
                        label="First Name"
                        name={`first_name_${form.id}`}
                        rules={[
                          {
                            required: true,
                            message: "Please enter your First Name.",
                          },
                        ]}
                      >
                        <Input size="large" maxLength={50} />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={10}>
                    <div className="mr-4">
                      <Form.Item
                        label="Last Name"
                        name={`last_name_${form.id}`}
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Last Name.",
                          },
                        ]}
                      >
                        <Input size="large" maxLength={50} />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={10}>
                    <div className="mr-4">
                      <Form.Item
                        label="Contact Number"
                        name={`contact_number_${form.id}`}
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Contact Number.",
                          },
                          {
                            min: 10,
                            message: "Contact number must be at least 10 digits.",
                          }
                        ]}
                      >
                         <Input
                        size="large"
                        maxLength={10}
                        value={phoneNumberInput} 
                        onKeyPress={handleKeyPress}
                        onChange={(e) => setPhoneNumberInput(e.target.value)} 
                        addonBefore={
                          <div className="pointer-events-none flex items-center">
                            <span className="text-small flex items-center gap-3 mr-2">
                              +1
                            </span>
                          </div>
                        }
                      />
                        {/* <PhoneInput
                          className="border border-[#d9d9d9] rounded-lg	px-[11px] h-[40px]"
                          international
                          defaultCountry="US"
                          value={phoneNumberInput}
                          onChange={setPhoneNumberInput}
                        /> */}
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={10}>
                    <div className="mr-4">
                      <Form.Item
                        label="Email ID"
                        name={`email_id_${form.id}`}
                        rules={[
                          {
                            required: true,
                            message: "Please enter your Email ID.",
                          },
                          {
                            type: "email",
                            message: "Please enter a valid Email ID.",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          maxLength={100}
                          onKeyPress={(event) => {
                            const regex = /^[a-zA-Z0-9@.]+$/; // Allow alphanumeric, @, and .
                            if (!regex.test(event.key)) {
                              event.preventDefault(); // Prevent invalid characters
                            }
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
          <div>
            <div className="font-semibold text-base mb-[10px]">
              Eligibility Criteria Details:
            </div>
            <Row>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="College Type"
                    name="college_type"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your College Type.",
                      },
                    ]}
                  >
                    <Select size="large" options={collegeType} />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Conference"
                    name="conference"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your conference.",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      maxLength={25}
                      onKeyPress={(event) => {
                        const regex = /^[a-zA-Z]+$/;
                        if (!regex.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Enrolment"
                    name="enrollment"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Enrolment.",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      maxLength={5}
                      onKeyPress={(event) => {
                        if (!/^[0-9]*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Number of Under Graduates"
                    name="under_graduate"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Number of Under Graduates.",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      maxLength={5}
                      onKeyPress={(event) => {
                        if (!/^[0-9]*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Majors offered"
                    name="major_offered"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Majors offered.",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      maxLength={3}
                      onKeyPress={(event) => {
                        if (!/^[0-9]*$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Average GPA"
                    name="average_gpa"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Average GPA.",
                      },
                      {
                        validator: (_, value) => {
                          if (value === undefined || value === "")
                            return Promise.resolve();
                          const gpa = parseFloat(value);
                          return gpa >= 0.0 && gpa <= 5.0
                            ? Promise.resolve()
                            : Promise.reject(
                                "GPA must be between 0.0 and 5.0."
                              );
                        },
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      maxLength={3} // Max length for values between 0.0 and 5.0
                      onChange={(event) => {
                        const input = event.target.value;
                        if (
                          /^(?:[0-4](?:\.\d{0,1})?|5(?:\.0?)?)?$/.test(input)
                        ) {
                          event.target.value = input;
                        } else {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="SAT/ACT required"
                    name="sat_act_status"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your SAT/ACT required.",
                      },
                    ]}
                  >
                    <Select size="large" options={SATACOption} />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="SAT score range"
                    name="sat_score"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your SAT score range.",
                      },
                    ]}
                  >
                    {/* <Progress percent={25} /> */}
                    <Slider
                      min={25}
                      max={75}
                      value={[25, satMaxValue]} // Fixed min, dynamic max
                      onChange={(value) =>
                        handleSliderChange(value, "sat_score")
                      }
                      tooltip={{
                        formatter: (value) => `${value}`,
                        open: true,
                        placement: "bottom",
                      }}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="ACT composite score range"
                    name="act_score"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your ACT composite score range.",
                      },
                    ]}
                  >
                    {/* <Progress percent={50} /> */}
                    <Slider
                      min={25}
                      max={75}
                      value={[25, actMaxValue]} // Fixed min, dynamic max
                      onChange={(value) =>
                        handleSliderChange(value, "act_score")
                      }
                      tooltip={{
                        formatter: (value) => `${value}`,
                        open: true,
                        placement: "bottom",
                      }}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <div className="font-semibold text-base mb-[10px]">Expenses :</div>
            <Row>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Base Tuition (in state)"
                    name="base_tution_state"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Base Tuition (in state).",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      maxLength={6}
                      onChange={(e) =>
                        onInputChange(e.target.value, "base_tution_state")
                      }
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Estimated Total Expenses (in state)"
                    name="total_expenses_state"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please enter your Number Estimated Total Expenses (in state).",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      maxLength={6}
                      onChange={(e) =>
                        onInputChange(e.target.value, "total_expenses_state")
                      }
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Base Tuition (out of state)"
                    name="base_tution_out_state"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please enter your Base Tuition (out of state).",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      maxLength={6}
                      onChange={(e) =>
                        onInputChange(e.target.value, "base_tution_out_state")
                      }
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4">
                  <Form.Item
                    label="Estimated Total Expenses (out of state)"
                    name="total_expenses_out_state"
                    rules={[
                      {
                        required: true,
                        message:
                          "Please enter your Estimated Total Expenses (out of state)*.",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      maxLength={6}
                      onChange={(e) =>
                        onInputChange(
                          e.target.value,
                          "total_expenses_out_state"
                        )
                      }
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <div className="text-right">
              <Button type="primary" htmlType="submit">
                {isEditing ? "Update" : "Add"}
              </Button>
            </div>
          </div>
        </Form>

        <ImageCropper
          isOpen={isUploadProfilePhotoOpen}
          onClose={handleUploadProfilePhotoClose}
          onCrop={handleCroppedImage}
        />
      </Card>
    </>
  );
};
export default CreateCollege;
