import { useState, useEffect } from "react";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Tooltip, Card, Typography, Select, Button } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Input } from "antd";
import SortingArrow from "../../Common/SortingArrow";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import PaginationComponent from "../../Common/Pagination";
import CollegeService from "../../service/CollegeService";
import { useAlert } from "../../contexts/AlertProvider";
import { useLoader } from "../../contexts/LoadingProvider";
const CollegeSer = new CollegeService();
const schoolType = [{
  value: "public",
  label: "Public",
},
{
  value: "private",
  label: "Private",
},];

const status = [
  {
    value: "active",
    label: "Active",
  },
  {
    value: "inactive",
    label: "Inactive",
  },
];

const ManageCollege = () => {
  const navigate = useNavigate();
  const { Search } = Input;
  const { setLoading } = useLoader();
  const { showAlert } = useAlert();
  const [address, setAddress] = useState(null);
  const onSearch = (value, _e, info) => console.log(info?.source, value);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [filters, setFilters] = useState({ page: 1, search: '', location:'', status: '', college_type:'', sport_id:'', division_id:'', limit:10,sort:'',order:'' });
  const [collegeListing, setCollegeListing] = useState([]);
  const [divisionListing, setDivisionListing] = useState([]);
  const [sportsListing, setSportsListing] = useState([]);
  const [locationListing, setLocationListing] = useState([]);

  const [searchData, setSearchData] = useState("");
  const [searchDataLocation, setSearchDataLocation] = useState("");
  const [divisionFilter, setDivisionFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState();
  const [typeFilter, setTypeFilter] = useState("");
  const [sportsFilter, setSportsFilter] = useState("");
  const [isDeleteId, setIsDeleteId] = useState();
  
  useEffect(() => {
    const fetchCommanData = async () => {
      try {
        const response = await CollegeSer.getDivisionList();
        if (response?.data) {
          const divisionArray = response.data.map(clg => ({
            value: clg.id,
            label: clg.name
          }));
          setDivisionListing(divisionArray);
        }
        const categoryData = await CollegeSer.getCategoryList();
        if (categoryData?.data) {
          const sportArray = categoryData.data.map(cat => ({
            value: cat.id,
            label:`${cat.name} (${cat.gender === 'male' ? 'M' : 'F'})`
          }));
          setSportsListing(sportArray);
        }
      } catch (error) {
        console.error("Failed to fetch college data:", error);
      }
    };
    fetchCommanData();
  }, []);

  useEffect(() => {
    const filterCollege = async () => {

      //setLoading(true);
      const response = await CollegeSer.getCollegeList(filters);
      setCollegeListing(response?.data);
      //setLoading(false);
    };
    
    filterCollege();
  }, [filters]);
  

  const handlePageChange = (page, size) => {
    setFilters({ ...filters, page, limit: size });
  };
  const handleDeleteOk =async () => {
    try {
    const response = await CollegeSer.updateCollegeStatus(isDeleteId,'deleted');
    setIsDeleteModalOpen(false);
    setCollegeListing((prev) => ({
      ...prev,
      rows: prev.rows.filter((college) => college.id !== isDeleteId),
      count: prev.count - 1,
    }));
    showAlert("College deleted successfully.", "success");
    } catch (error) {
      let msg = CollegeSer.errorMessage(error);
      showAlert(msg, "error");
    }
  };
  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDivisionChange = (value) => {
    let jsonString = "";
    if(value?.length !== 0)
     jsonString = JSON.stringify(value);
    
    setDivisionFilter(jsonString);
    setFilters({ ...filters, page:1, division_id: jsonString });
  };
  const handleStatusChange = (value) => {
    setStatusFilter(value);
    setFilters({ ...filters, page:1, status: value });
  };
  const handleTypeChange = (value) => {
    let jsonString = "";
    if(value?.length !== 0)
     jsonString = JSON.stringify(value);
    
     setTypeFilter(jsonString);
    setFilters({ ...filters, page:1, college_type: jsonString });
  };
  const handleSportsChange = (value) => {
    let jsonString = "";
    if(value?.length !== 0)
     jsonString = JSON.stringify(value);
    
     setSportsFilter(jsonString);
    setFilters({ ...filters, page:1, sport_id: jsonString });
  };
  const clearFilter = ()=>{
    setDivisionFilter("");
    setStatusFilter();
    setTypeFilter("");
    setSportsFilter("");
    setSearchData("");
    setSearchDataLocation("");
    setFilters({ ...filters, page: 1, search: '', status: '', location:'' ,college_type:'', sport_id:'', division_id:'', limit:10,sort:'',order:'' });
  }
  const handleSearchChange = (e) => {
    const value = e;
    setSearchData(value);
    if (value.length > 2) {
      setFilters({ ...filters, page:1, search: value });
    } else if (value.length === 0) {
      setFilters({ ...filters, page:1, search: '' });
    }
  };

  const handleLocationChange = (e) => {
    const value = e.target.value;
    setSearchDataLocation(value);
    if (value.length > 2) {
      setFilters({ ...filters, page:1, location: value });
    } else if (value.length === 0) {
      setFilters({ ...filters, page:1, location: '' });
    }
  };

  const getSportCategoryNames = (data, type, action='') => {
    if(type==="category"){
      if(action==='tool')
        return data?.slice(0, 2)?.map(item => `${item.name} (${item.gender === 'male' ? 'M' : 'F'})`).join(", ")
      else
        return data?.slice(2)?.map(item => `${item.name} (${item.gender === 'male' ? 'M' : 'F'})`).join(", ");
    }else{
      return data?.map(item => item.name).join(", ");
    }
  };
  const handleDeletePopupClick = (id) => {
    setIsDeleteModalOpen(true)
    setIsDeleteId(id);
  };//updateCollegeStatus
  const filterOption = (input, option) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };
  const handleSort = (field, key) => {
    setFilters({ ...filters, page:1, sort:field, order: key });
  };
  return (
    <>
      <Card>
        <div className="flex justify-between	mb-4 gap-5">
          <div className="">
            <Typography className="text-xl font-semibold" align={"left"}>
              Manage Colleges
            </Typography>
          </div>
          <div>
          <Search
            placeholder="Search college by name...."
            value={searchData}
            style={{
              width: 250,
            }}
            className="mx-1.5 cursor-pointer"
            onChange={(e) => {
              const value = e.target.value;
              if (value === "" || value[0] !== " ") {
                handleSearchChange(value);
              }
            }}
          />
            <Button
              type="primary"
              size={14}
              className="mx-1.5 cursor-pointer"
              icon={<PlusOutlined />}
              onClick={() => navigate("/create-college")}
            >
              Add College
            </Button>
          </div>
        </div>
        <div className="flex justify-end	mb-4 gap-3">
          <Select
            style={{
              width: 150,
            }}
            placeholder="Division"
            maxTagCount="responsive"
            options={divisionListing}
            mode="multiple"
            allowClear
            showSearch={false}
            value={divisionFilter ? JSON.parse(divisionFilter) : []}
            onChange={handleDivisionChange}
            className="mx-1.5 cursor-pointer"
          />
          <Select
            style={{
              width: 150,
            }}
            placeholder="Type of School"
            maxTagCount="responsive"
            options={schoolType}
            mode="multiple"
            allowClear
            showSearch={false}
            value={typeFilter ? JSON.parse(typeFilter) : []}
            onChange={handleTypeChange}
            className="mx-1.5 cursor-pointer"
          />
          {/* <GooglePlacesAutocomplete
            apiKey={process.env.REACT_APP_GOOGLE_CLIENT_KEY}
            selectProps={{
              address,
              onChange: setAddress,
              placeholder: "Locations",
              styles: {
                input: (provided) => ({
                  ...provided,
                  color: 'black',
                }),
                option: (provided) => ({
                  ...provided,
                  color: 'black',
                }),
              },
            }}
          /> */}
          <Input
            placeholder="Locations"
            value={searchDataLocation}
            style={{
              width: 250,
            }}
            className="mx-1.5 cursor-pointer"
            onChange={handleLocationChange}
          />
          {/* <Select
            style={{
              width: 150,
            }}
            placeholder="Locations"
            maxTagCount="responsive"
            options={locationListing}
            mode="multiple"
            showSearch={false}
            allowClear
            className="mx-1.5 cursor-pointer"
          /> */}
          <Select
            style={{
              width: 150,
            }}
            placeholder="Sports"
            maxTagCount="responsive"
            options={sportsListing}
            mode="multiple"
            filterOption={filterOption}
            allowClear
            value={sportsFilter ? JSON.parse(sportsFilter) : []}
            onChange={handleSportsChange}
            className="mx-1.5 cursor-pointer"
          />
          <Select
            style={{
              width: 150,
            }}
            placeholder="Status"
            options={status}
            value={statusFilter}
            onChange={handleStatusChange}
            showSearch={false}
            filterOption={false}
            className="ml-1.5 cursor-pointer"
          />
          <Button type="link" size={14} className="cursor-pointer" onClick={clearFilter}>
            Clear Filter
          </Button>
        </div>
        <div className="relative overflow-x-auto">
          <table className="w-full  text-left	 text-sm  text-gray-500">
            <thead className=" text-gray-700 capitalize text-xs	 bg-gray-50">
              <tr>
                <th scope="col" className="px-3 py-3 whitespace-nowrap">
                  College Name <SortingArrow onSort={handleSort}  field="name" />
                </th>
                <th scope="col" className="px-3 py-3">
                  Division
                </th>
                <th scope="col" className="px-3 py-3">
                  Type of School <SortingArrow onSort={handleSort} field="college_type" />
                </th>
                <th scope="col" className="px-3 py-3">
                  Location <SortingArrow onSort={handleSort} field="address"/>
                </th>
                <th scope="col" className="px-3 py-3">
                  Sports
                </th>
                <th scope="col" className="px-3 py-3 text-center">
                  Status <SortingArrow onSort={handleSort} field="status"/>
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-center whitespace-nowrap vertical-top"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
            {collegeListing?.rows?.length > 0 ? (
              collegeListing.rows.map((college, index) => (
              <tr key={index} className="bg-white border-b">
                <th
                  scope="row"
                  className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top cursor-pointer"
                  onClick={() => navigate(`/view-college/${college.slug}`)}
                >
                  {college.name}
                </th>
                <td className="px-3 py-4 vertical-top">{getSportCategoryNames(college.division_id,"division")}</td>
                <td className="px-3 py-4 vertical-top">{college.college_type}</td>
                <td className="px-3 py-4 vertical-top">{college.address}</td>
                <td className="px-3 py-4 vertical-top">
                {getSportCategoryNames(college.sport_category_id,"category", "tool")}{" "}
                {college.sport_category_id?.length > 2 && (
                  <Tooltip title= {getSportCategoryNames(college.sport_category_id,"category")}>
                    <ExclamationCircleOutlined className="mx-1.5 cursor-pointer" />
                  </Tooltip>
                )}
                </td>
                <td className="px-3 py-4 vertical-top">
                  <p className={`text-xs font-medium bg-[#01940033] rounded-lg text-center p-2 ${
                      college?.status === 'pending' ? 'text-[#faad14] bg-[#ffd70b40]' :
                      (college?.status === 'rejected' || college?.status === 'inactive' ) ? 'text-[#DC362E] bg-[#DC362E40]' : 'text-[#019400] bg-[#01940033]'
                    }`}>
                    {college?.status}
                  </p>
                </td>
                <td className="px-3 py-4 text-center whitespace-nowrap vertical-top">
                  <Tooltip title="Edit">
                    <EditOutlined
                      onClick={() => navigate(`/create-college/${college.slug}`)}
                      className="mx-1.5 cursor-pointer"
                    />
                  </Tooltip>
                  <Tooltip title="Delete">
                    <DeleteOutlined
                      className="mx-1.5 cursor-pointer"
                      onClick={() => handleDeletePopupClick(college.id)}
                    />
                  </Tooltip>
                </td>
              </tr>
              ))
              ) : (
                <tr>
                  <td colSpan={6} className="text-center px-3 py-4 text-gray-500">
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between items-center">
          <div className="font-semibold text-base">
            Total count : <span>{collegeListing?.count}</span>
          </div>
          {collegeListing?.count > 10 && (
          <PaginationComponent
            currentPage={filters.page}
            total={collegeListing?.count}
            pageSize={filters.limit}
            onPageChange={handlePageChange} />
          )}
        </div>
      </Card>
      {/* delete modal */}
      <ConfirmationModal
        ConfirmationHeading="Delete"
        ConfirmationParagraph="Are you sure you want to delete this College?"
        isOpen={isDeleteModalOpen}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
    </>
  );
};
export default ManageCollege;
