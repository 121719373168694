import { useState, useEffect } from "react";
import { Tooltip, Card, Divider, Typography, Row, Col, Switch } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import CoachService from "../../service/CoachService";
import { useAlert } from "../../contexts/AlertProvider";
import { useLoader } from "../../contexts/LoadingProvider";
import moment from 'moment';
const CoachSer = new CoachService();
const CoachDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { showAlert } = useAlert();
  const { setLoading } = useLoader();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [coachData, setCoachData] = useState(false);
  const [isActive, setIsActive] = useState(false);
  
  useEffect(() => {
    if (id) {
      try {
      const fetchProfileData = async () => {
        const response = await CoachSer.getCoachDetails(id);
        setCoachData(response?.data);
        if(!response?.data){
          navigate('/manage-coach');
        }
        setIsActive((response?.data?.status ==="active")?true:false);
      };
      fetchProfileData();
      }catch (error) {
        navigate('/manage-coach');
        let msg = CoachSer.errorMessage(error);
        showAlert(msg, "error");
      }
    }
  }, [id]);
  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };
  const handleToggle = async (checked) => {
    try {
      let status = (checked)?'active':'inactive';
      const response = await CoachSer.updateCoachStatus(id, status);
      setIsActive(checked);
      showAlert(response?.message, "success");
    } catch (error) {
      navigate('/manage-coach');
      let msg = CoachSer.errorMessage(error);
      showAlert(msg, "error");
    }
  };
  const handleDeleteOk =async () => {
    setLoading(true);
    try {
    const response = await CoachSer.deleteCoach(id);
    setIsDeleteModalOpen(false);
    navigate('/manage-coach');
    showAlert(response?.message, "success");
    } catch (error) {
      let msg = CoachSer.errorMessage(error);
      showAlert(msg, "error");
    }
    setLoading(false);
  };
  return (
    <>
      <Card>
        <div className="flex justify-between items-center mb-4 gap-3">
          <div>
            <Typography className="text-xl font-semibold" align={"left"}>
              <LeftOutlined
                className="text=lg text-black mr-2 cursor-pointer"
                onClick={() => navigate(-1)}
              />
              Coach Details
            </Typography>
          </div>
          <div className="flex items-center gap-3 ">
          <Switch checked={isActive} onChange={handleToggle} />
            <Tooltip title="Edit">
              <EditOutlined
                onClick={() => navigate(`/create-coach/${coachData.id}`)}
                className="mx-1.5 cursor-pointer"
              />
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteOutlined
                className="mx-1.5 cursor-pointer"
                onClick={() => setIsDeleteModalOpen(true)}
              />
            </Tooltip>
          </div>
        </div>
        <Divider />
        <Card>
          <div>
            <div className="font-semibold text-lg mb-[10px]">
              Personal Information
            </div>
            <Row>
              <Col span={10}>
                <div className="mr-4 my-5">
                  <div className="font-semibold text-base">Coach ID</div>
                  <div className="text-sm">#{coachData?.id}</div>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4 my-5">
                  <div className="font-semibold text-base">Created date</div>
                  <div className="text-sm">{moment(coachData?.created_at).format('MM/DD/YYYY')}</div>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4 my-5">
                  <div className="font-semibold text-base">Name</div>
                  <div className="text-sm">{coachData?.first_name} {coachData.last_name}</div>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4 my-5">
                  <div className="font-semibold text-base">Phone number</div>
                  <div className="text-sm">{coachData?.contact_number}</div>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4 my-5">
                  <div className="font-semibold text-base">Email ID</div>
                  <div className="text-sm">{coachData?.email_address}</div>
                </div>
              </Col>
              <Col span={10}>
                <div className="mr-4 my-5">
                  <div className="font-semibold text-base">
                    Associated College
                  </div>
                  <div className="text-sm">{coachData?.college?.name} </div>
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <div className="font-semibold text-lg mb-[10px]">
              Social Media Links
            </div>
            <Row>
              {/* <Col span={10}>
                <div className="mr-4 my-5">
                  <div className="font-semibold text-base">linkedIn</div>
                  <div className="text-sm">url//https//rfwgdjkwjk</div>
                </div>
              </Col> */}
              {coachData?.social_media_link?.map((social) =>(
              <Col span={10}>
                <div className="mr-4 my-5">
                  <div className="font-semibold text-base">{social.name==="fb"?"Facebook":(social?.name==="insta"?"Instagram":"Twitter")}</div>
                  <div className="text-sm">{(social?.link)?(social?.link):'-' }</div>
                </div>
              </Col>
              ))}
              
            </Row>
          </div>
          <div>
            <div className="font-semibold text-lg mb-[10px]">
              Current Position
            </div>
            <Row>
              <Col span={10}>
                <div className="mr-4 my-5">
                  <div className="font-semibold text-base">Title</div>
                  <div className="text-sm">{coachData?.current_position}</div>
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </Card>
      {/* delete modal */}
      <ConfirmationModal
        ConfirmationHeading="Delete"
        ConfirmationParagraph="Are you sure you want to delete this Coach?"
        isOpen={isDeleteModalOpen}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
    </>
  );
};
export default CoachDetail;
