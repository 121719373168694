import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState } from "react";
import { ConfigProvider, Layout } from "antd";
import HeaderComponent from "../Layout/HeaderComponent";
import LeftSidebar from "../Layout/LeftSidebar";
import Login from "../Pages/Auth/Login";
import Dashboard from "../Pages/Dashboard";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import ResetPassword from "../Pages/Auth/ResetPassword";
import ManageCategory from "../Pages/ManageCategory";
import PrivacyPolicy from "../Pages/CMS/PrivacyPolicy";
import TermsAndConditions from "../Pages/CMS/TermsAndConditions";
import ManageMediaBlog from "../Pages/ManageMediaBlog";
import CreateBlog from "../Pages/ManageMediaBlog/CreateBlog";
import ManageAthlete from "../Pages/ManageAthlete";
import ViewAthlete from "../Pages/ManageAthlete/ViewAthlete";
import EditAthlete from "../Pages/ManageAthlete/EditAthlete";
import ManageCollege from "../Pages/ManageColleges";
import ViewCollege from "../Pages/ManageColleges/ViewCollege";
import CreateCollege from "../Pages/ManageColleges/CreateCollege";
import ManageCoach from "../Pages/ManageCoach";
import CoachDetail from "../Pages/ManageCoach/CoachDetail";
import CreateCoach from "../Pages/ManageCoach/CreateCoach";
import ManageSubscriptionPlan from "../Pages/ManageSubscriptionPlan";
import ViewSubscriptionDetails from "../Pages/ManageSubscriptionPlan/ViewSubscriptionDetails";
import EditSubscriptionDetails from "../Pages/ManageSubscriptionPlan/EditSubscriptionDetails";
import ManageProfile from "../Pages/ManageProfile";
import ManageTransaction from "../Pages/ManageTransaction";
import MotionReportGeneration from "../Pages/MotionReportGeneration";
import RequestDetailPage from "../Pages/MotionReportGeneration/RequestDetailPage";
import AcceptingRejecting from "../Pages/AcceptingRejecting";
import { useLoader } from "../contexts/LoadingProvider";
import Loader from "../Common/Loader";
import MarketingVideos from "../Pages/MarketingVideos";
const { Content } = Layout;
const Routing = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { loading } = useLoader();
  const toggleSidebar = (collapsedState) => {
    setCollapsed(collapsedState);
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#0071F1",
        },
      }}
    >
      {loading && <Loader />}
      <Router>
        <Routes>
          {/* auth Route */}
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/accepting-rejecting/:status/:code"
            element={<AcceptingRejecting />}
          />

          {/* Layout with Sidebar and Header */}
          <Route
            path="/*"
            element={
              <Layout style={{ minHeight: "100vh" }}>
                <LeftSidebar
                  collapsed={collapsed}
                  toggleCollapsed={toggleSidebar}
                />
                <Layout>
                  <HeaderComponent toggleCollapsed={toggleSidebar} />
                  <Content>
                    <Routes>
                      {/* Dashboard Route */}
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route
                        path="/manage-category"
                        element={<ManageCategory />}
                      />
                      <Route
                        path="/manage-media-blog"
                        element={<ManageMediaBlog />}
                      />
                      <Route path="/create-blog" element={<CreateBlog />} />
                      <Route
                        path="/manage-athlete"
                        element={<ManageAthlete />}
                      />
                      <Route
                        path="/view-athlete/:id"
                        element={<ViewAthlete />}
                      />
                      <Route
                        path="/edit-athlete/:id"
                        element={<EditAthlete />}
                      />
                      <Route
                        path="/manage-college"
                        element={<ManageCollege />}
                      />
                      <Route path="/view-college" element={<ViewCollege />} />
                      <Route path="/view-college/:slug" element={<ViewCollege />} />
                      <Route
                        path="/create-college"
                        element={<CreateCollege />}
                      />
                      <Route
                        path="/create-college/:id"
                        element={<CreateCollege />}
                      />
                      <Route path="/manage-coach" element={<ManageCoach />} />
                      <Route
                        path="/coach-detail/:id"
                        element={<CoachDetail />}
                      />
                      <Route path="/create-coach" element={<CreateCoach />} />
                      <Route
                        path="/create-coach/:id"
                        element={<CreateCoach />}
                      />
                      <Route
                        path="/privacy-policy"
                        element={<PrivacyPolicy />}
                      />
                      <Route
                        path="/terms-and-conditions"
                        element={<TermsAndConditions />}
                      />
                      <Route
                        path="/manage-subscription-plan"
                        element={<ManageSubscriptionPlan />}
                      />
                      <Route
                        path="/manage-transaction"
                        element={<ManageTransaction />}
                      />
                      <Route
                        path="/view-subscription-details/:id"
                        element={<ViewSubscriptionDetails />}
                      />
                      <Route
                        path="/edit-subscription-details/:id"
                        element={<EditSubscriptionDetails />}
                      />
                      <Route
                        path="/motion-report-generation"
                        element={<MotionReportGeneration />}
                      />
                      <Route
                        path="/request-detail"
                        element={<RequestDetailPage />}
                      />
                      <Route
                        path="/request-detail/:id"
                        element={<RequestDetailPage />}
                      />
                      <Route
                        path="/manage-profile"
                        element={<ManageProfile />}
                      />
                       <Route
                        path="/marketing-videos"
                        element={<MarketingVideos />}
                      />
                    </Routes>
                  </Content>
                </Layout>
              </Layout>
            }
          />
        </Routes>
      </Router>
    </ConfigProvider>
  );
};
export default Routing;
