import { useState, useEffect } from "react";
import { Button, Card, Form, Typography, Upload, Col, Row, Input } from "antd";
import { UserOutlined, CloseOutlined } from "@ant-design/icons";
import AuthService from "../../service/AuthService";
import { useLoader } from "../../contexts/LoadingProvider";
import { useAlert } from "../../contexts/AlertProvider";
import { Config } from "../../constants/Config";
import { refreshToken } from '../../service/Refreshtoken';
import { useToken } from "../../contexts/TokenProvider";
import CommonService from "../../service/CommonService";
const CommonSer = new CommonService();

const AuthSer = new AuthService();

const ManageProfile = () => {
  const [form] = Form.useForm(); // Initialize form instance
  const [imageUrl, setImageUrl] = useState(null);
  const { decodedToken, saveToken } = useToken();
  const { setLoading } = useLoader();
  const { showAlert } = useAlert();

  useEffect(() => {
    const fetchProfileData = async () => {
      const response = await AuthSer.getProfile();
      setImageUrl(response?.data?.profile_image);
      form.setFieldsValue(response?.data);
    };
    fetchProfileData();
  }, [form]);

  const allowedTypes = ["image/jpeg", "image/png"];
  
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const maxSizeInMB = 5; // 5MB limit
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

    if (selectedFile) {
      if (allowedTypes.includes(selectedFile.type)) {
        if (selectedFile.size <= maxSizeInBytes) {
          uploadFileToS3Bucket(selectedFile);
        } else {
          showAlert("File size exceeds 5MB limit.", "error");
        }
      } else {
        showAlert("Invalid file type.", "error");
      }
    }
  };

  const uploadFileToS3Bucket = async (file) => {
    if (!file) {
      showAlert("Please select a file first!", "error");
      return;
    }
    try {
      // await addObject(file?.name, file);
      // const publicUrl = `https://${Config?.s3BucketName}.s3.${Config?.s3Region}.amazonaws.com/${file?.name}`;
      const renamedFile = new File([file], file?.name, { type: file.type });
      let payload = {
        user_id: decodedToken?.id,
        image: renamedFile,
        field: "profile_image",
        type: "image",
        page: "manage_profile",
      }
      const formData = new FormData();
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });
      let response = await CommonSer.uploadFile(formData);
      const publicUrl = response?.data;
      setImageUrl(publicUrl);
    } catch (error) {
      showAlert(error.message, "error");
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await AuthSer.updateProfile({ ...values, profile_image: imageUrl });
      let refdata = await refreshToken(decodedToken.email);
      saveToken(refdata);
      showAlert("Profile updated successfully!", "success");
    } catch (error) {
      showAlert(error.message, "error");
    }
    setLoading(false);
  };


  const extractKeyFromUrl = (url) => {
    const urlParts = url.split("/");
    return urlParts.slice(3).join("/");
  };
  const deleteFileFromS3BucketByUrl = async () => {
    if(imageUrl){
      const key = extractKeyFromUrl(imageUrl);
      try {
        let payload = {
          user_id: decodedToken?.id,
          url: imageUrl,
          field: "profile_image",
          type: "image",
          page: "manage_profile",
        };
        let response = await CommonSer.deleteFile(payload);
    //    await AuthSer.updateProfile({ profile_image: ""});
        let refdata = await refreshToken(decodedToken.email);
        saveToken(refdata);
        setImageUrl("");
      } catch (error) {
        showAlert(error.message, "error");
      }
    }else{
      setImageUrl("");
    }
  };

  return (
    <Card>
      <div className="flex items-center justify-between relative mb-4">
        <Typography className="text-xl font-semibold mb-0" align={"left"}>
          Profile
        </Typography>
      </div>
      <div className="relative overflow-x-auto">
        <Form
          form={form} // Bind form instance
          layout="vertical"
          style={{ maxWidth: 600, width: "100%" }}
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={handleSubmit}
          onFinishFailed={(errorInfo) => {
            // Handle form submission failure (validation errors)
            showAlert("Please fix the errors in the form.", "error");
          }}
        >
          <Form.Item>
            <div className="flex">
              <Upload
                name="avatar"
                listType="picture-card"
                className="relative"
                showUploadList={false}
                beforeUpload={(file) => {
                  handleFileChange({ target: { files: [file] } });
                  return false; // Prevent automatic upload
                }}
              >
              {imageUrl ? (
                  <img src={imageUrl} alt="Profile Preview" style={{ width: '100%', height: '100%' }} />
                ) : (
                  <button
                    style={{ border: 0, background: "none" }}
                    type="button"
                    className="flex flex-col items-center justify-center text-gray-600 hover:text-gray-800"
                  >
                    <UserOutlined className="text-2xl" />
                    <div className="mt-2">Upload</div>
                  </button>
                )}
                
                {imageUrl && (
                 <CloseOutlined
                 className="cursor-pointer absolute right-[-8px] top-0 p-[5px] rounded-full bg-red-600 text-white"
                 onClick={(e) => {
                   e.stopPropagation(); 
                   deleteFileFromS3BucketByUrl();
                 }}
               />

                )}
              </Upload>
            </div>
          </Form.Item>
          <Row>
            <Col span={24}>
              <Form.Item
                label="First Name"
                name="first_name" // Set name to bind with form
                rules={[{ required: true, message: "Please enter your first name." }]}
              >
                <Input
                  size="large"
                  maxLength={25}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Last Name"
                name="last_name" // Set name to bind with form
                rules={[{ required: true, message: "Please enter your last name." }]}
              >
                <Input
                  size="large"
                  maxLength={25}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Email Address" name="email">
                <Input disabled size="large" />
              </Form.Item>
            </Col>
          </Row>
          <div className="flex justify-end gap-3">
            <Button type="primary" htmlType="submit">Save</Button>
          </div>
        </Form>
      </div>
    </Card>
  );
};

export default ManageProfile;
